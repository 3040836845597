import { Flight } from "../objects/flight";

export default (a: Flight, b: Flight) => {
    let is_same = true;
    if ((a.flight_stop_over !== b.flight_stop_over)
        || (a.provider_id !== b.provider_id) || (a.total_price !== b.total_price && a.flight_source === b.flight_source)){
            is_same = false;

    }else{
        a.outbounds.map((a_outbound, outbound_index) => {
            if (a_outbound.flight_duration !== b.outbounds[outbound_index].flight_duration){
                is_same = false
            }else{
                a_outbound.legs.map((a_leg, leg_index) => {
                    let b_leg = b.outbounds[outbound_index].legs[leg_index];
                    if ((a_leg.arrival_datetime_lt !== b_leg.arrival_datetime_lt) || (a_leg.baggage_allowance.number_of_pieces !== b_leg.baggage_allowance.number_of_pieces)
                        || (a_leg.baggage_allowance.max_weight.value !== b_leg.baggage_allowance.max_weight.value) || (a_leg.baggage_allowance.max_weight.unit !== b_leg.baggage_allowance.max_weight.unit)
                        || (a_leg.booking_code !== b_leg.booking_code && a.flight_source === b.flight_source) || (a_leg.cabin_class !== b_leg.cabin_class) || (a_leg.departure_datetime_lt !== b_leg.departure_datetime_lt)
                        || (a_leg.destination_airport !== b_leg.destination_airport) || (a_leg.marketing_airline !== b_leg.marketing_airline) || (a_leg.marketing_flight_number !== b_leg.marketing_flight_number)
                        || (a_leg.origin_airport !== b_leg.origin_airport) || (a_leg.origin_station || b_leg.origin_station )){
                        is_same = false
                    }
                })
            }
        })
    }
    return is_same;
}