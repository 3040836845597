declare var WP_BASE_HREF: string;
// Dependencies
import { render } from 'react-dom';
import { Router } from '@reach/router';
import { SnackbarProvider } from 'notistack';
import React, { lazy, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
// Material UI Components
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
// Components
import PublicRoute from './utils/publicRoute';
import PrivateRoute from './utils/privateRoute';
import ProtectedRoute from './utils/protectedRoute'
import Loading from './component/loading/loading';
import CloseSnackbar from './component/common/components/CloseSnackbar';
// Utils
import store from './store';
import i18n from './translation/i18n';
// Theme
const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#319FE3'
      }
    }
  });
// Login
const Login = lazy(() => import('./component/login/login'));
// Logout
const Logout = lazy(() => import('./component/login/logout'));
// NotFound
const NotFound = lazy(() => import('./component/notFound/notFound'));
// FlightGroups
const FlightGroups = lazy(() => import('./component/flight/flightgroups/FlightGroups'));
// FlightSearch
const FlightSearch = lazy(() => import('./component/flight/flightsearch/FlightSearch'));
// FlightSelected
const FlightSelected = lazy(() => import('./component/flight/flightselected/FlightSelected'));
const FlightPnrRetrieved = lazy(() => import('./component/flight/flightselected/flightPnrRetrieved'));
// FlightConfirmed
const FlightConfirmed = lazy(() => import('./component/flight/FlightConfirmed'));
// ConfirmMail
const ConfirmMail = lazy(() => import('./component/login/confirmMail'));
// Profile
const Profile = lazy(() => import("./component/profile/profile"));
// Register
const Register = lazy(() => import('./component/user/register'));
// Reset Password
const ResetPassword = lazy(() => import('./component/resetPasswordBis/resetPassword'));
// Trip
const GetTrip = lazy(() => import('./component/trip/GetTrip'));
const TripInfo = lazy(() => import('./component/trip/tripInfo'));
const TripList = lazy(() => import('./component/trip/tripList'));
// User interface
const UserInterface = lazy(() => import('./component/user/UserInterface'));

render(
    <ReduxProvider store={ store }>
        <I18nextProvider i18n={ i18n }>
            <SnackbarProvider anchorOrigin={{vertical: 'top', horizontal: 'center'}} disableWindowBlurListener={ true } action={key => CloseSnackbar({key})}>
                <CssBaseline/>
                <Suspense fallback={ <Loading/> }>
                    <MuiThemeProvider theme={theme}>
                        <Router basepath={ WP_BASE_HREF }>
                            <PublicRoute path={ '/' } component={ NotFound }/>
                            <PublicRoute path={ '/login' } component={ Login }/>
                            <PublicRoute path={ '/register' } component={ Register }/>
                            <PublicRoute path={ '/reset-password' } component={ResetPassword} />
                            <PublicRoute path={ '/not-found' } component={ NotFound }/>
                            <PublicRoute path={ '/confirm-mail'} component={ConfirmMail} />
                            <ProtectedRoute path={ '/get-trip'} component={GetTrip} />
                            <ProtectedRoute path={ '/flight-groups'} component={FlightGroups} origin_url="groups" display={true} setDisplay={null} setSearchGroup={null} use_cache={true} />
                            <ProtectedRoute path={ '/flight-search/:use_cache'} component={FlightSearch} origin_url='search' use_cache={true} />
                            <ProtectedRoute path={ '/selected-flight'} component={FlightSelected} origin_url="selected" />
                            <ProtectedRoute path={ '/selected-flight/:retry'} component={FlightSelected} origin_url="selected" />
                            <ProtectedRoute path={ '/pnr-retrieved' } component={ FlightPnrRetrieved }/>
                            <ProtectedRoute path={ '/confirmed-flight'} component={FlightConfirmed} />
                            <ProtectedRoute path={ '/trip-list' } component={ TripList } filter_user={false} />
                            <ProtectedRoute path={ '/trip-info/:id' } component={TripInfo} />
                            <ProtectedRoute path={ '/trip-info/:id/flight-groups' } component={FlightGroups} origin_url="trip-groups" display={true} setDisplay={null} setSearchGroup={null} use_cache={true} />
                            <ProtectedRoute path={ '/trip-info/:id/flight-search/:use_cache' } component={FlightSearch} origin_url="trip-search" use_cache={true} />
                            <ProtectedRoute path={ '/trip-info/:id/selected-flight'} component={FlightSelected} origin_url="trip-selected" />
                            <ProtectedRoute path={ '/profile' } component={Profile} />
                            <ProtectedRoute path={ '/my-account'} component={UserInterface} />
                            <PrivateRoute path={ '/logout'} component={Logout} />
                        </Router>
                    </MuiThemeProvider>
                </Suspense>
            </SnackbarProvider>
        </I18nextProvider>
    </ReduxProvider>,
    document.getElementById('app')
);
