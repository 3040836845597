import moment from 'moment';

import { Airline } from '../objects/airline';
import { Airport, AirportsObj } from '../objects/airport';
import { Alliance } from '../objects/alliance';
import { BillingInfoMode } from '../objects/billingInfoMode';
import { Flight } from '../objects/flight';
import { FlightState } from '../objects/flightState';
import { FlightGroup } from '../objects/flightGroup';
import { Journey } from '../objects/journey';
import { LuggageOption } from '../objects/luggageOption';
import { MatrixFlight } from '../objects/matrixFlight';
import { Outbound } from '../objects/outbound';
import { OutrightMode } from '../objects/outrightMode';
import { Passenger } from '../objects/passenger';
import { Provider } from '../objects/provider';
import { rateType } from '../../price/objects/price';
import { ReservationMode } from '../objects/reservationMode';
import { SelectedSeat } from '../objects/selectedSeat';
import { StationsObj } from '../objects/station';
import { StopoverDuration } from '../objects/stopoverDuration';

import {
    ADD_BAGGAGE_OPTION,
    ADD_FETCH_DATA,
    ADD_FLIGHT_NEW_FARES,
    ADD_HOVER_TO_MATRIX,
    ADD_JOURNEY_TO_FLIGHT,
    ADD_PAYMENT_REMARK,
    ADD_SELECTED_PRICE,
    ADD_TRAVELER,
    APPLY_REPRICE_FLIGHT,
    CHANGE_FLIGHT_GROUPS_MODE,
    CHANGE_MATRIX_LUGGAGE,
    CHANGE_OUTRIGHT_MODE,
    CHANGE_PAY_REMARK_DESCRIPTION,
    CHANGE_PAY_REMARK_TYPE,
    CHANGE_PAY_REMARK_VALUE,
    CHANGE_RESERVATION_MODE,
    CLEAR_FILTERS,
    CREATE_FLIGHT_HOURS,
    CREATE_STOP_OVER_DURATION,
    DELETE_FLIGHT_GROUP_AT_INDEX,
    DISPLAY_LIST,
    EDIT_TRAVELER,
    EMPTY_INSURANCES,
    EMPTY_LUGGAGE,
    EMPTY_PAYMENT_REMARKS,
    EMPTY_SEATS,
    FlightActionTypes,
    GET_PROVIDERS,
    IMPORT_PRODUCT_PARAMS,
    LOAD_SAVED_STATE,
    OPEN_PRICE_VARIATION,
    REMOVE_BAGGAGE_OPTION,
    REMOVE_ERROR,
    REMOVE_PASSENGER_BAGGAGE,
    REMOVE_PNR_REMARK,
    REMOVE_SELECTED_INSURANCE,
    REMOVE_SELECTED_PRICE,
    REMOVE_SELECTED_SEAT,
    REMOVE_TRAVELER,
    REPLACE_GROUP,
    RESET_ERROR,
    RESET_FETCH_ERROR,
    RESET_MATRIX_HOVER,
    RESET_SELECTED_PRICE,
    RESET_STATE,
    REVERT_JOURNEYS,
    SAVE_FLIGHT_GROUPS,
    SELECT_AIRLINES,
    SELECT_FLIGHT,
    SELECT_INSURANCE,
    SELECT_SEAT,
    SET_ACCEPT_CANCELLATION,
    SET_ACCEPT_CONFIRMATION,
    SET_AIRPORT_JOURNEY,
    SET_AIRPORT_JOURNEY_MULTI_DEST,
    SET_AIRPORT_JOURNEY_ROUNDTRIP,
    SET_BILLING_INFO_MODE,
    SET_BUYER,
    SET_CHECKED_LUGGAGE,
    SET_DEFAULT_AIRPORTS,
    SET_DIRECT_FLIGHT,
    SET_DURATION_FILTER,
    SET_DURATION_SLIDER,
    SET_ERROR,
    SET_FEES_REMARK,
    SET_FETCH_DATA,
    SET_FETCH_DATA_STATUS,
    SET_FETCH_ERROR,
    SET_FETCH_MATRIX_STATUS,
    SET_FLEXIBLE_DATE,
    SET_FLIGHT_TO_SHOW_DETAIL,
    SET_FLIGHT_HOURS,
    SET_FLIGHT_HOURS_SLIDE,
    SET_FLIGHT_LIST,
    SET_GET_OPTIONS,
    SET_GROUP_INDEX,
    SET_INCLUDED_LUGGAGE,
    SET_INITIAL_DEPOSIT,
    SET_INSURANCE_CHOICES,
    SET_JOURNEY,
    SET_JOURNEY_TYPE,
    SET_MANUAL_INFO,
    SET_MATRIX_DATA,
    SET_MATRIX_SELECTED_DAY,
    SET_MIN_DEPOSIT,
    SET_MULTIDEST_CLASS,
    SET_MULTIDEST_START_DATE,
    SET_NEW_ENCRYPT_DATA,
    SET_ONEWAY_CLASS,
    SET_ONEWAY_JOURNEY,
    SET_ONEWAY_ORIGIN_DATE,
    SET_OPEN_ADD_LUGGAGE,
    SET_OPEN_CHANGE_MODE,
    SET_OPEN_CONFIRM_FLIGHT,
    SET_OPEN_GIR_REMINDER,
    SET_OPTION_LOADED,
    SET_OPTION_MODE_ONLY,
    SET_OPTIONS_ERROR,
    SET_PARAMS_SEARCH,
    SET_PAYBOX_PROVIDER,
    SET_PAYMENT_SCHEDULES,
    SET_PLEDG_PROVIDER,
    SET_PNR_NUMBER,
    SET_PRESENCE_PROVIDER,
    SET_PRICE_FILTER,
    SET_PRICE_SLIDER,
    SET_PRICE_VARIATION,
    SET_PRIVATE_PRICE,
    SET_PUBLIC_PRICE,
    SET_PVT_REMARK,
    SET_REMOVE_BILLING_ID,
    SET_REMOVE_INSURANCE,
    SET_REMOVE_LUGGAGE,
    SET_REMOVE_SEAT,
    SET_ROUNDTRIP_CLASS,
    SET_ROUNDTRIP_JOURNEY,
    SET_ROUNDTRIP_ORIGIN_DATE,
    SET_ROUNDTRIP_RETURN_DATE,
    SET_SEAT_CURRENT_SEGMENT,
    SET_SEATS_BY_SEGMENT,
    SET_START_DATE_FILTER,
    SHOW_ALL_AIRLINES,
    SHOW_ALL_AIRPORTS,
    SHOW_ALL_STATIONS,
    SHOW_ALL_STOP_OVER_AIRPORTS,
    SUB_JOURNEY_TO_FLIGHT,
    SWAP_MULTIDEST_JOURNEY,
    TOGGLE_ACTIVE_STOPOVER_FILTER,
    TOGGLE_ADD_LOYALTY_CARD,
    TOGGLE_AIRLINE,
    TOGGLE_AIRPORT,
    TOGGLE_ALL_STOP_OVER_DURATION,
    TOGGLE_ALLIANCE,
    TOGGLE_ALLOTMENT_PRICE,
    TOGGLE_BACK_TO_LIST,
    TOGGLE_DAY_FLIGHT,
    TOGGLE_DETAIL,
    TOGGLE_NIGHT_FLIGHT,
    TOGGLE_OPEN_SKIP_OPTION,
    TOGGLE_PRIVATE_PRICE,
    TOGGLE_PROVIDER,
    TOGGLE_PUBLIC_PRICE,
    TOGGLE_SHOW_ALL_STOP_OVER,
    TOGGLE_STATION,
    TOGGLE_STOP_OVER_AIRPORT,
    TOGGLE_STOP_OVER_DURATION,
    TOGGLE_SYNCHRONIZED_STOP_OVER,
    TOGGLE_WITH_APPLICANT,
    UPDATE_FILTERS,
    UPDATE_FLIGHT_LIST,
    UPDATE_RETRIEVE_PNR,
    SET_INITIAL_SEARCH,
    SET_LOWEST_PRICE_MOBILE,
    TOGGLE_IS_FLEXIBLE
} from './actionTypes';

import CompareFlight from '../functions/CompareFlight';

const initialJourney: Journey = {
    start_date: null,
    class_type: 0,
    origin: null,
    destination: null
}

const initialGroup: FlightGroup = {
    company: [],
    direct_flight: true,
    error: [],
    flexible_date: true,
    included_luggage: false,
    is_km: false,
    journey: [initialJourney, initialJourney],
    journey_type: 1,
    one_stop: false,
    passenger_open: false,
    private_price: false,
    provider: null,
    public_price: false,
    travelers: []
}

const initialState: FlightState = {
    accept_cancellation: false,
    accept_confirmation: false,
    add_loyalty_card: false,
    airlines: null,
    airports: null,
    alliances: null,
    allotment_price: false,
    back_to_list: false,
    billing_info_mode: BillingInfoMode.New,
    buyer: null,
    change_airport: false,
    checked_luggage: {
        disabled: true,
        included: false,
        one: false,
        two: false,
        zero: false,
    },
    currency: "EUR",
    currency_symbol: "€",
    default_airports: [],
    detailed_index: null,
    distance_unit: null,
    fees_remark: 0,
    fetch_error: null,
    fetched_status: null,
    filter_max_start_date: null,
    filter_max_duration: null,
    filter_max_price: null,
    filter_min_start_date: null,
    filter_min_duration: null,
    filter_min_price: null,
    filter_time: null,
    flight_groups: [initialGroup],
    flight_groups_mode: "search",
    flight_list: [],
    flight_list_render: [],
    index_group: null,
    initial_deposit: 0,
    initial_search: [],
    insurances_choices: [],
    isUpdate: false,
    is_day_flight: false,
    is_night_flight: false,
    lowest_price: null,
    lowest_price_mobile: null,
    lowest_price_one: null,
    lowest_price_two: null,
    lowest_price_zero: null,
    matrix_data: null,
    matrix_flight: null,
    matrix_flight_one: null,
    matrix_flight_two: null,
    matrix_flight_zero: null,
    // matrix_index_start: null,
    // matrix_index_return: null,
    matrix_length: null,
    matrix_luggage_nb: "two",
    matrix_outbound_date: null,
    matrix_return_date: null,
    matrix_status: null ,
    max_start_date: null,
    max_duration: null,
    max_price: null,
    min_duration: null,
    min_deposit: 0,
    min_price: null,
    min_start_date: null,
    new_encrypt_data: null,
    not_refundable: false,
    open_add_luggage: false,
    open_change_mode: false,
    open_confirm_flight: false,
    open_edit_segment: false,
    open_gir_reminder: false,
    open_price_variation: false,
    open_remove_insurance: false,
    open_remove_luggage: false,
    open_remove_seat: false,
    open_skip_option: false,
    option_loaded: false,
    option_mode_only: false,
    outright_mode: OutrightMode.Once,
    params_search: "no",
    payment_four_times: null,
    payment_three_times: null,
    paybox_provider: null,
    pledg_provider: null,
    pnr_number: null,
    payment_remarks: [],
    presence_provider: null,
    previous_flight_groups: [],
    price_variation: {
        group_passenger: 0,
        reload: false,
        status: false,
        variation: null,
    },
    private_price: false,
    providers: [],
    public_price: false,
    pvt_remark: "",
    refundable: false,
    remove_billing_id: null,
    remove_option_baggage_index: null,
    remove_option_segment_key: null,
    remove_option_outbound_index: null,
    remove_option_traveler_id: null,
    reservation_mode: window.location !== window.parent.location ? ReservationMode.Outright : ReservationMode.Option,
    seat_current_segment: 0,
    seats_by_segment: [],
    selected_flight: null,
    selected_insurances: {},
    selected_price: 0,
    selected_seats: [],
    show_all_airlines: true,
    show_all_airports: true,
    show_all_alliances: true,
    show_all_providers: true,
    show_all_stations: true,
    show_all_stop_over: true,
    show_all_stop_over_airport: true,
    show_all_stop_over_duration: true,
    slider_max_duration: null,
    slider_max_price: null,
    slider_min_duration: null,
    slider_min_price: null,
    slider_time: null,
    sort_value: 1,
    stations: null,
    stop_over: null,
    stop_over_active: {
        none: false,
        one: false,
        two: false,
        more: false
    },
    stop_over_airport: null,
    stop_over_duration: null,
    synchronized_stop_over: false,
    tmp_seat: {},
    trace_id: null,
    with_applicant: false,
    retrieve_pnr: '',
    flight_to_show_detail: null,
    is_flexible: true,
};

export default (state = initialState, action: FlightActionTypes): FlightState => {
    switch(action.type){
        case TOGGLE_IS_FLEXIBLE: {
            return {
                ...state,
                is_flexible: !state.is_flexible
            };
        }
        case ADD_BAGGAGE_OPTION: {
            const { option_key, options, selected_flight, outbound_index, segment_key, traveler_id, option_index } = action.payload;
            let flight_list = state.flight_list_render.slice();
            let copy_outbounds: Array<Outbound> = [];
            let copy_flights: Array<Flight> = [];
            let baggage = options.find(item => item.key_optional_service === option_key);
            if (baggage !== undefined){
                flight_list.map((flight, flight_index) => {
                    if (flight_index === selected_flight){
                        let copy_flight = Object.assign({}, flight);
                        copy_flight.outbounds.map((outbound, parsed_outbound_index) => {
                            let copy_outbound = Object.assign({}, outbound);
                            if (parsed_outbound_index === outbound_index) {
                                copy_outbound.legs.map((leg, leg_index) => {
                                    if (segment_key === leg.segment_key || segment_key === leg.key){
                                        let copy_leg = Object.assign({}, leg);
                                        let paid_options = Object.assign({}, copy_leg.paid_options);
                                        paid_options[traveler_id] = paid_options[traveler_id] || [];
                                        if (baggage !== null) {
                                            if (paid_options[traveler_id][option_index] !== undefined) {
                                                paid_options[traveler_id][option_index] = baggage;
                                            }
                                            else {
                                                paid_options[traveler_id].push(baggage);
                                            }
                                        }
                                        else {
                                            if (option_index !== 0) {
                                                paid_options[traveler_id] = paid_options[traveler_id].filter((el, el_index) => {
                                                    return el_index !== option_index;
                                                });
                                            }
                                            else {
                                                delete paid_options[traveler_id];
                                            }
                                        }
                                        copy_leg.paid_options = paid_options;
                                        copy_outbound.legs[leg_index] = copy_leg;
                                    }
                                })
                                outbound = copy_outbound;
                            }
                            copy_outbounds.push(outbound);
                        });
                        copy_flight.outbounds = copy_outbounds;
                        flight = copy_flight;
                    }
                    copy_flights.push(flight);
                });
                return {
                    ...state,
                    flight_list_render: copy_flights
                }
            }else{
                return {
                    ...state
                }
            }
        }
        case ADD_FETCH_DATA: {
            const {
                alliances, airlines, airports, flight_list, max_duration, max_price, max_start_date, min_duration, min_price, min_start_date, stations, stop_over_airport, stop_over
            } = action.payload;
            console.log("state.matrix_luggage_nb : ", state.matrix_luggage_nb);
            let matrix_luggage_nb = state.matrix_luggage_nb;
            let new_airlines: Array<Airline> | null = state.airlines !== null ? state.airlines.slice() : null;
            let new_airports: AirportsObj | null = Object.assign({}, state.airports);
            let new_alliances: Array<Alliance> | null = state.alliances !== null ? state.alliances.slice() : null;
            let new_flight_list: Array<Flight> = state.flight_list.slice();
            let new_flight_list_render: Array<Flight> = state.flight_list_render.slice();
            let new_max_duration: number | null = state.max_duration !== null ? state.max_duration : 0;
            let new_max_price: number | null = state.max_price !== null ? state.max_price : 0;
            let new_max_start_date: string | null = state.min_start_date;
            let new_min_duration: number | null = state.min_duration !== null ? state.min_duration : 0;
            let new_min_price: number | null = state.min_price !== null ? state.min_price : 0;
            let new_min_start_date: string | null = state.min_start_date;
            let new_stations: StationsObj | null = Object.assign({}, state.stations);
            let new_stop_over_airport: null | Array<Record<string | number, Airport>> = state.stop_over_airport !== null ? state.stop_over_airport.slice() : null;

            if (new_airlines !== null){
                if (airlines !== null){
                    airlines.map((airline) => {
                        if (new_airlines !== null){
                            let found_airline = new_airlines.find(item => item.name === airline.name);
                            if (found_airline === undefined)
                                new_airlines.push(airline)
                            else if (found_airline.price !== undefined && (found_airline.price === 0 || found_airline.price > airline.price)){
                                let new_airline_index = new_airlines.findIndex(item => item.name === airline.name);
                                new_airlines[new_airline_index] = airline;
                            }
                        }
                    })
                }
            }else
                new_airlines = airlines;

            if (new_airports !== null){
                if (airports !== null){
                    Object.keys(airports).forEach(key => {
                        if (new_airports !== null) {
                            if(new_airports[key] === undefined)
                               new_airports[key] = airports[key]
                            else if (new_airports[key].price === 0 || airports[key].price < new_airports[key].price){
                                new_airports[key] = airports[key];
                            }
                        }
                    })
                }
            }else
                new_airports = airports;

            if (new_alliances !== null){
                if (alliances !== null){
                    alliances.map((alliance) => {
                        let found_alliance = new_alliances.find(item => item.name === alliance.name);
                        if (new_alliances !== null){
                            if (found_alliance === undefined)
                                new_alliances.push(alliance)
                        }else if (found_alliance !== undefined && (found_alliance.price === 0 || found_alliance.price > alliance.price)){
                            let new_alliance_index = new_alliances.findIndex(item => item.name === alliance.name);
                            new_alliances[new_alliance_index] = alliance;
                        }
                    })
                }
            }else
                new_alliances = alliances;

            // keep only flights with corresponding luggage
            let luggage_nb = 0;
            switch(matrix_luggage_nb){
                case "zero":
                    break
                case "one":
                    luggage_nb = 1;
                    break
                case "two":
                    luggage_nb = 2;
                    break
            }
            if (new_flight_list !== null){
                if (flight_list !== null){
                    flight_list.map((flight) => {
                        if (new_flight_list.find(item => item.flight_key === flight.flight_key && item.fare_type === flight.fare_type) === undefined && !new_flight_list.some(item => CompareFlight(item, flight)))
                        new_flight_list.push(flight);
                    })
                }
            }else
                new_flight_list = flight_list;

            if (new_flight_list_render !== null){
                if (flight_list !== null){
                    flight_list.map((flight) => {
                        let flight_luggage: number | null = null;
                        // check that every segment have same number of luggage
                        let nb_luggage_by_segment: Array<number> = [];
                        flight.outbounds.map((segment) => {
                            nb_luggage_by_segment.push(segment.legs[0].baggage_allowance.number_of_pieces);
                        })
                        let all_same_luggage = true;
                        nb_luggage_by_segment.map((segment_nb_luggage) => {
                            if (segment_nb_luggage !== nb_luggage_by_segment[0]){
                                all_same_luggage = false;
                            }
                        })
                        if (all_same_luggage)
                            flight_luggage = nb_luggage_by_segment[0];

                        if (flight_luggage === luggage_nb && new_flight_list_render.find(item => item.flight_key === flight.flight_key && item.fare_type === flight.fare_type) === undefined && !new_flight_list_render.some(item => CompareFlight(item, flight)))
                            new_flight_list_render.push(flight);
                    })
                }
            }else
                new_flight_list_render = flight_list;

            if (max_duration !== null)
                new_max_duration = Math.max(new_max_duration, max_duration);
            else
                new_max_duration = null;

            if (max_price !== null)
                new_max_price = Math.max(new_max_price, max_price);
            else
                new_max_price = null;

            if (min_duration !== null)
                new_min_duration = Math.min(new_min_duration, min_duration);
            else
                new_min_duration = null;

            if (min_price !== null)
                new_min_price = Math.min(new_min_price, min_price);
            else
                new_min_price = null;

            if (min_start_date !== null){
                if (new_min_start_date !== null){
                    if (moment(min_start_date).isBefore(new_min_start_date)){
                        new_min_start_date = min_start_date;
                    }
                }else{
                    new_min_start_date = min_start_date;
                }
            }

            if (max_start_date !== null){
                if (new_max_start_date !== null){
                    if (moment(max_start_date).isBefore(new_max_start_date)){
                        new_max_start_date = max_start_date;
                    }
                }else{
                    new_max_start_date = max_start_date;
                }
            }

            if (new_stations !== null){
                if (stations !== null){
                    Object.keys(stations).forEach(key => {
                        if (new_stations !== null){
                            if(new_stations[key] === undefined)
                                new_stations[key] = stations[key]
                            else if (new_stations[key].price === 0 || stations[key].price < new_stations[key].price){
                                new_stations[key] = stations[key];
                            }
                        }
                    })
                }
            }else
                new_stations = stations;

            if (new_stop_over_airport !== null){
                if (stop_over_airport !== null){
                    stop_over_airport.map((stop_over_outbound, outbound_index) => {
                        Object.keys(stop_over_outbound).forEach(key => {
                            if (new_stop_over_airport !== null){
                                if(new_stop_over_airport[outbound_index] !== undefined){
                                    if(new_stop_over_airport[outbound_index][key] === undefined)
                                        new_stop_over_airport[outbound_index][key] = stop_over_outbound[key];
                                    else if (new_stop_over_airport[outbound_index][key].price !== undefined && stop_over_outbound.price !== undefined && new_stop_over_airport[outbound_index][key].price < stop_over_outbound[key].price)
                                        new_stop_over_airport[outbound_index][key] = stop_over_outbound[key];
                                }
                            }
                        })
                    })
                }
            }else
                new_stop_over_airport = stop_over_airport;

            // sort to have allotment if lowest price first
            new_flight_list.sort((a, b) => { return (parseFloat(a.total_price) - parseFloat(b.total_price))});
            new_flight_list_render.sort((a, b) => { return (parseFloat(a.total_price) - parseFloat(b.total_price))});

            let new_matrix_flight: null | Array<MatrixFlight> = null;
            let new_lowest_price = state.lowest_price;
            // change price in calendar in case allotment has lower price
            if (state.matrix_flight !== null && new_lowest_price !== null){
                new_matrix_flight = state.matrix_flight.slice();
                new_matrix_flight.map((flight, flight_index) => {
                    if (flight.selected && new_flight_list_render.length > 0){
                        let new_selected_flight = Object.assign({}, new_matrix_flight[flight_index]);
                        new_selected_flight.total_price = parseFloat(new_flight_list_render[0].total_price);
                        if (new_selected_flight.total_price < new_lowest_price){
                            new_lowest_price = new_selected_flight.total_price;
                        }
                        new_matrix_flight[flight_index] = new_selected_flight;
                    }
                })
            };

            return {
                ...state,
                airlines: new_airlines !== null ? new_airlines.sort((a, b) => {return (a.price - b.price);}) : null,
                airports: new_airports,
                alliances: new_alliances,
                filter_max_duration: new_max_duration,
                filter_max_price: new_max_price !== null ? Math.ceil(new_max_price) : null,
                filter_max_start_date: new_max_start_date,
                filter_min_duration: new_min_duration,
                filter_min_price: new_min_price !== null ? Math.floor(new_min_price) : null,
                filter_min_start_date: new_min_start_date,
                flight_list: new_flight_list,
                flight_list_render: new_flight_list_render,
                lowest_price: new_lowest_price,
                matrix_flight: new_matrix_flight,
                max_duration: new_max_duration,
                max_price: new_max_price,
                max_start_date: new_max_start_date,
                min_duration: new_min_duration,
                min_price: new_min_price,
                min_start_date: new_min_start_date,
                slider_max_duration: new_max_duration,
                slider_max_price: new_max_price !== null ? Math.ceil(new_max_price) : null,
                slider_min_duration: new_min_duration,
                slider_min_price: new_min_price !== null ? Math.floor(new_min_price) : null,
                stations: new_stations,
                stop_over: stop_over,
                stop_over_airport: new_stop_over_airport
            }
        }
        case ADD_FLIGHT_NEW_FARES: {
            let { flight_index, group_to_replace, new_fares, original_flight } = action.payload;
            let new_flight_list = state.flight_list.slice();
            let copy_flight = Object.assign({}, new_flight_list[flight_index]);
            copy_flight.new_fares = new_fares;
            copy_flight.adult = original_flight.adult;
            copy_flight.baby = original_flight.baby;
            copy_flight.children = original_flight.children;
            copy_flight.total_price = (parseFloat(original_flight.total_price) + new_fares[0].price_variation + new_fares[0].taxes_variation).toString();
            if (group_to_replace.length === 1 && copy_flight.outbounds.length === 1){
                // add departure or return if one segment selected
                let new_outbounds = original_flight.outbounds.slice();
                new_outbounds[group_to_replace[0]] = copy_flight.outbounds[0]
                copy_flight.outbounds = new_outbounds;
            }
            new_flight_list[flight_index] = copy_flight;
            return {
                ...state,
                flight_list: new_flight_list,
                flight_list_render: new_flight_list
            }

        }
        case ADD_HOVER_TO_MATRIX: {
            if (state.matrix_flight !== null){
                const { index_start, index_return } = action.payload;
                let matrix_flights = state.matrix_flight.slice();
                let copy_matrix_flight: Array<MatrixFlight> = [];
                matrix_flights.map((matrix_flight, index) => {
                    let copy_flight = Object.assign({}, matrix_flight);
                    if (copy_flight.index_start_date === index_start && copy_flight.index_return_date === index_return) {
                        copy_flight.hover_selected = true;
                    }
                    if ((copy_flight.index_start_date <= index_start && copy_flight.index_return_date === index_return) || (copy_flight.index_start_date === index_start && copy_flight.index_return_date !== undefined && copy_flight.index_return_date <= index_return)) {
                        copy_flight.hover = true;
                    }
                    matrix_flight = copy_flight;
                    copy_matrix_flight.push(matrix_flight);
                });
                return {
                    ...state,
                    matrix_flight: copy_matrix_flight
                }
            }else{
                return state
            }
        }
        case ADD_JOURNEY_TO_FLIGHT: {
            const { group_index, journey } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.journey.push(journey);
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case ADD_PAYMENT_REMARK: {
            const remark = action.payload;
            let copy_payment_remarks = state.payment_remarks.slice();
            let new_remark= Object.assign({}, remark);
            copy_payment_remarks.push(new_remark);
            return {
                ...state,
                payment_remarks: copy_payment_remarks
            }
        }
        case ADD_SELECTED_PRICE: {
            const price = action.payload;
            let new_price = state.selected_price + price;
            return {
                ...state,
                selected_price: new_price
            }
        }
        case ADD_TRAVELER: {
            const { group_index, traveler } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let copy_travelers = copy_group.travelers.slice();
            copy_travelers.push(traveler);
            copy_group.travelers = copy_travelers;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case APPLY_REPRICE_FLIGHT: {
            const { currency_id, margin_reduction_percentage, new_ltd, new_pax_prices, pax_object, price_variation, taxes_variation, total_margin } = action.payload;
            let copy_flight_list = state.flight_list_render.slice();
            let copy_flight = copy_flight_list[0];
            copy_flight.last_ticketing_date = new_ltd;
            console.log('copy_flight.total_price : ', copy_flight.total_price);
            copy_flight.total_price = (parseFloat(copy_flight.total_price) + price_variation + taxes_variation - (margin_reduction_percentage*total_margin/100)).toString();
            console.log('copy_flight.total_price : ', copy_flight.total_price);
            console.log('price_variation : ', price_variation);
            console.log('taxes_variation : ', taxes_variation);
            console.log('margin_reduction_percentage : ', margin_reduction_percentage);
            console.log('total_margin : ', total_margin);
            new_pax_prices.map((pax_price) => {
                let passenger_price: Passenger = {
                    cancel_penalty: null,
                    change_penalty: null,
                    is_tax_included: pax_price.is_tax_included,
                    margin: undefined,
                    number: 1,
                    taxes: [{
                        custom_rate: false,
                        custom_rate_type: rateType.Percentage,
                        is_network: false,
                        is_surcom: false,
                        master_price: true,
                        owner: 1,
                        purchase_currency: currency_id,
                        purchase_price: pax_price.taxes_to,
                        selling_currency: currency_id,
                        selling_price: pax_price.taxes_agency
                    }],
                    total_price: pax_price.prices_agency
                }

                switch(pax_price.pax_code){
                    case "ADT":
                        passenger_price.number = pax_object.ADT;
                        copy_flight.adult = passenger_price;
                        break
                    case "ITX":
                        passenger_price.number = pax_object.ADT;
                        copy_flight.adult = passenger_price;
                        break
                    case "INN":
                        passenger_price.number = pax_object.CNN;
                        copy_flight.children = passenger_price;
                        break
                    case "CNN":
                        passenger_price.number = pax_object.CNN;
                        copy_flight.children = passenger_price;
                        break
                    case "CHD":
                        passenger_price.number = pax_object.CNN;
                        copy_flight.children = passenger_price;
                        break
                    case "INF":
                        passenger_price.number = pax_object.INF;
                        copy_flight.baby = passenger_price;
                        break
                }
            })
            copy_flight_list[0] = copy_flight;
            return {
                ...state,
                flight_list: copy_flight_list,
                flight_list_render: copy_flight_list
            }
        }
        case CHANGE_FLIGHT_GROUPS_MODE: {
            const mode = action.payload;
            return {
                ...state,
                flight_groups_mode: mode
            }
        }
        case CHANGE_MATRIX_LUGGAGE: {
            const matrix_luggage_nb = action.payload;
            const flight_list = state.flight_list;
            let new_flight_list_render: Array<Flight> = []
            switch(matrix_luggage_nb){
                case "zero":
                    if (flight_list !== null){
                        flight_list.map((flight) => {
                            if (flight.outbounds[0].legs[0].baggage_allowance.number_of_pieces === 0 && new_flight_list_render.find(item => item.flight_key === flight.flight_key && item.fare_type === flight.fare_type) === undefined)
                                new_flight_list_render.push(flight);
                        })
                    }
                    return {
                        ...state,
                        matrix_flight: state.matrix_flight_zero,
                        matrix_luggage_nb: matrix_luggage_nb,
                        flight_list_render: new_flight_list_render,
                        lowest_price: state.lowest_price_zero
                    }
                case "one":
                    if (flight_list !== null){
                        flight_list.map((flight) => {
                            if (flight.outbounds[0].legs[0].baggage_allowance.number_of_pieces === 1 && new_flight_list_render.find(item => item.flight_key === flight.flight_key && item.fare_type === flight.fare_type) === undefined)
                                new_flight_list_render.push(flight);
                        })
                    }
                    return {
                        ...state,
                        matrix_flight: state.matrix_flight_one,
                        matrix_luggage_nb: matrix_luggage_nb,
                        flight_list_render: new_flight_list_render,
                        lowest_price: state.lowest_price_one
                    }
                case "two":
                    if (flight_list !== null){
                        flight_list.map((flight) => {
                            if (flight.outbounds[0].legs[0].baggage_allowance.number_of_pieces === 2 && new_flight_list_render.find(item => item.flight_key === flight.flight_key && item.fare_type === flight.fare_type) === undefined)
                                new_flight_list_render.push(flight);
                        })
                    }
                    return {
                        ...state,
                        matrix_flight: state.matrix_flight_two,
                        matrix_luggage_nb: matrix_luggage_nb,
                        flight_list_render: new_flight_list_render,
                        lowest_price: state.lowest_price_two
                    }
            }
        }
        case CHANGE_OUTRIGHT_MODE: {
            const new_mode = action.payload;
            return {
                ...state,
                outright_mode: new_mode
            }
        }
        case CHANGE_PAY_REMARK_DESCRIPTION: {
            const { remark_index, new_description } = action.payload;
            let copy_payment_remarks = state.payment_remarks.slice();
            let copy_remark = Object.assign({}, copy_payment_remarks[remark_index]);
            copy_remark.description = new_description;
            copy_payment_remarks[remark_index] = copy_remark;
            return {
                ...state,
                payment_remarks: copy_payment_remarks
            }
        }
        case CHANGE_PAY_REMARK_TYPE: {
            const { remark_index, new_type } = action.payload;
            let copy_payment_remarks = state.payment_remarks.slice();
            let copy_remark = Object.assign({}, copy_payment_remarks[remark_index]);
            copy_remark.remark_name = new_type;
            copy_payment_remarks[remark_index] = copy_remark;
            return {
                ...state,
                payment_remarks: copy_payment_remarks
            }
        }
        case CHANGE_PAY_REMARK_VALUE: {
            const { remark_index, value } = action.payload;
            let payment_remarks = state.payment_remarks.slice();
            let copy_remark = Object.assign({}, payment_remarks[remark_index]);
            copy_remark.amount = typeof value === "string" ? parseFloat(value) : value;
            payment_remarks[remark_index] = copy_remark;
            return {
                ...state,
                payment_remarks: payment_remarks
            }
        }
        case CHANGE_RESERVATION_MODE: {
            const new_mode = action.payload;
            return {
                ...state,
                reservation_mode: new_mode
            }
        }
        case CLEAR_FILTERS: {
            const filter_time = action.payload;
            let airlines = state.airlines !== null ? state.airlines.slice() : null;
            if (airlines !== null){
                airlines.forEach(key => {
                    key.displayed = false;
                });
            }
            let alliances = state.alliances !== null ? state.alliances.slice() : null;
            if (alliances !== null){
                alliances.forEach(key => {
                    key.displayed = false;
                });
            }
            let airports = Object.assign({}, state.airports);
            Object.keys(airports).forEach(key => {
                airports[key].displayed = false;
            });
            let stations = Object.assign({}, state.stations);
            Object.keys(stations).forEach(key => {
                stations[key].displayed = false;
            });
            let stop_over_airport = state.stop_over_airport !== null ? state.stop_over_airport.slice() : null;
            if (stop_over_airport !== null){
                stop_over_airport.map((stop_over) => {
                    let copy_stopover = Object.assign({}, stop_over);
                    Object.keys(copy_stopover).forEach(key => {
                        copy_stopover[key].displayed = false;
                    });
                })
            }

            let new_stop_over_duration: Array<StopoverDuration> = [];
            if (state.stop_over_duration !== null){
                state.stop_over_duration.map((outbound) => {
                    let copy_outbound = Object.assign({}, outbound);
                    Object.keys(outbound).map((key) => {
                        copy_outbound[key] = false;
                    })
                    new_stop_over_duration.push(copy_outbound);
                })
            }

            let providers = state.providers;
            providers.forEach(key => {
                key.displayed = false;
            })
            return {
                ...state,
                alliances: alliances,
                airlines: airlines,
                airports: airports,
                allotment_price: false,
                change_airport: false,
                checked_luggage: {
                    included: false,
                    zero: false,
                    one: false,
                    two: false,
                    disabled: true
                },
                filter_max_duration: state.max_duration,
                filter_max_price: state.max_price !== null ? Math.ceil(state.max_price) : null,
                filter_max_start_date: state.max_start_date,
                filter_min_duration: state.min_duration,
                filter_min_price: state.min_price !== null ? Math.floor(state.min_price) : null,
                filter_min_start_date: state.min_start_date,
                filter_time: filter_time,
                is_day_flight: false,
                is_night_flight: false,
                isUpdate: true,
                not_refundable: false,
                private_price: false,
                providers: providers,
                public_price: false,
                refundable: false,
                sort_value: 24,
                stations: stations,
                stop_over_active: {
                    none: false,
                    one: false,
                    more: false,
                    two: false
                },
                stop_over_airport: stop_over_airport,
                show_all_alliances: true,
                show_all_airlines: true,
                show_all_airports: true,
                show_all_providers: true,
                show_all_stations: true,
                show_all_stop_over: true,
                show_all_stop_over_airport: true,
                show_all_stop_over_duration: true,
                slider_max_duration: state.max_duration,
                slider_max_price: state.max_price !== null ? Math.ceil(state.max_price) : null,
                slider_min_duration: state.min_duration,
                slider_min_price: state.min_price !== null ? Math.floor(state.min_price) : null,
                slider_time: filter_time,
                stop_over_duration: new_stop_over_duration.length !== 0 ? new_stop_over_duration : null
            }
        }
        case CREATE_FLIGHT_HOURS: {
            const nb_journeys = action.payload;
            let new_time = [];
            var i: number;
            for (i=0 ; i<nb_journeys ; i++){
                new_time.push({
                    arrival: [0, 24],
                    departure: [0, 24]
                })
            }
            return {
                ...state,
                filter_time: new_time,
                slider_time: new_time,
                show_all_stop_over_duration: true,
                isUpdate: true
            }
        }
        case CREATE_STOP_OVER_DURATION: {
            const nb_journeys = action.payload;
            let new_stop_over_duration = [];
            var i: number;
            for (i=0 ; i<nb_journeys ; i++){
                new_stop_over_duration.push({
                    two_hours: false,
                    two_ten_hours: false,
                    ten_hours: false,
                    displayed: true
                })
            }
            return {
                ...state,
                stop_over_duration: new_stop_over_duration,
                show_all_stop_over_duration: true
            }
        }
        case DELETE_FLIGHT_GROUP_AT_INDEX: {
            const group_index = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            copy_flight_groups = copy_flight_groups.filter((elem, index_elem) => {
                return index_elem !== group_index;
            });

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case DISPLAY_LIST: {
            const flight_list_render: Flight[] = [];
            let luggages_nb = 0;
            let matrix_luggage_nb = action.payload.nb_luggage_matrix;
            console.log("state.matrix_luggage_nb 1 : ", matrix_luggage_nb)
            switch(matrix_luggage_nb){
                case "zero":
                    break
                case "one":
                    luggages_nb = 1;
                    break
                case "two":
                    luggages_nb = 2;
                    break
            }
            action.payload.list.map((flight) => {
                let flight_luggage: number | null = null;
                // check that every segment have same number of luggage
                let nb_luggage_by_segment: Array<number> = [];
                flight.outbounds.map((segment) => {
                    nb_luggage_by_segment.push(segment.legs[0].baggage_allowance.number_of_pieces);
                })
                let all_same_luggage = true;
                nb_luggage_by_segment.map((segment_nb_luggage) => {
                    if (segment_nb_luggage !== nb_luggage_by_segment[0]){
                        all_same_luggage = false;
                    }
                })
                if (all_same_luggage)
                    flight_luggage = nb_luggage_by_segment[0];

                if (flight_luggage === luggages_nb)
                    flight_list_render.push(flight);
            });
            return {
                ...state,
                flight_list_render: flight_list_render,
                isUpdate: false
            }
        }
        case EDIT_TRAVELER: {
            const { group_index, traveler } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let copy_travelers = copy_group.travelers.slice();
            copy_travelers.map((group_traveler, group_traveler_index) => {
                if (group_traveler.id === traveler.id){
                    let new_traveler = Object.assign({}, traveler);
                    copy_travelers[group_traveler_index] = new_traveler;
                }
            })
            copy_group.travelers = copy_travelers;
            copy_flight_groups[group_index] = copy_group;
            return {
                ...state,
                flight_groups: copy_flight_groups,
            }
        }
        case EMPTY_INSURANCES: {
            return {
                ...state,
                selected_insurances: {}
            }
        }
        case EMPTY_LUGGAGE: {
            let copy_flight_list = state.flight_list_render.slice();
            // let copy_flight = Object.assign({}, copy_flight_list[state.selected_flight]);
            copy_flight_list.map((flight, flight_index) => {
                let copy_flight = Object.assign({}, flight);
                copy_flight.outbounds.map((outbound, outbound_index) => {
                    let copy_outbound = Object.assign({}, outbound);
                    copy_outbound.legs.map((leg, leg_index) => {
                        let copy_leg = Object.assign({}, leg);
                        copy_leg.paid_options = {};
                        copy_outbound.legs[leg_index] = copy_leg;
                    });
                    copy_flight.outbounds[outbound_index] = copy_outbound;
                });
                copy_flight_list[flight_index] = copy_flight;
            });

            return {
                ...state,
                flight_list_render: copy_flight_list
            };
            //
            //
            // if (state.selected_flight !== null){
            //     let copy_flight_list = state.flight_list_render.slice();
            //     let copy_flight = Object.assign({}, copy_flight_list[state.selected_flight]);
            //     copy_flight.outbounds.map((outbound, outbound_index) => {
            //         let copy_outbound = Object.assign({}, outbound);
            //         copy_outbound.legs.map((leg, leg_index) => {
            //             let copy_leg = Object.assign({}, leg);
            //             copy_leg.paid_options = {};
            //             copy_outbound.legs[leg_index] = copy_leg;
            //         })
            //         copy_flight.outbounds[outbound_index] = copy_outbound;
            //     })
            //     copy_flight_list[state.selected_flight] = copy_flight;
            //     return {
            //         ...state,
            //         flight_list_render: copy_flight_list
            //     }
            // }else{
            //     return {
            //         ...state
            //     }
            // }
        }
        case EMPTY_PAYMENT_REMARKS: {
            return {
                ...state,
                payment_remarks: []
            }
        }
        case EMPTY_SEATS: {
            let copy_seats_by_segments = state.seats_by_segment.slice();
            copy_seats_by_segments.map((segment, segment_index) => {
                segment.seat_rows.map((row, row_index) => {
                    row.seats.map((seat, seat_index) => {
                        if (seat.chosen !== undefined && seat.chosen){
                            let copy_seat = Object.assign({}, seat);
                            copy_seat.chosen = false;
                            copy_seat.chosen_traveler = undefined;
                            copy_seats_by_segments[segment_index].seat_rows[row_index].seats[seat_index] = copy_seat;
                        }
                    })
                })
            })
            return {
                ...state,
                seats_by_segment: copy_seats_by_segments,
                selected_seats: []
            }
        }
        case GET_PROVIDERS: {
            const { group_index, provider } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let copy_providers = state.providers.slice();
            let provider_reducer: Provider = {
                code: provider.code,
                displayed: false,
                id: provider.id,
                logo: provider.logo,
                name: provider.name
            }
            copy_group.provider = provider.id;
            copy_flight_groups[group_index] = copy_group;
            if (!copy_providers.some(item => item.id === provider_reducer.id)){
                copy_providers.push(provider_reducer);
            }

            return {
                ...state,
                flight_groups: copy_flight_groups,
                providers: copy_providers
            }
        }
        case IMPORT_PRODUCT_PARAMS: {
            const { airlines, airports, flight_groups, flight_list, stations, trace_id } = action.payload;
            return {
                ...state,
                airlines: airlines,
                airports: airports,
                flight_groups: flight_groups,
                flight_list: flight_list,
                flight_list_render: flight_list,
                stations: stations,
                trace_id: trace_id
            }
        }
        case LOAD_SAVED_STATE: {
            const { accept_cancellation, accept_confirmation, alliances, airlines, airports, billing_info_mode, buyer, currency, currency_symbol, fees_remark,
                    flight_groups, flight_list, index_group, initial_deposit, lowest_price, lowest_price_one, lowest_price_two, lowest_price_zero, matrix_data, matrix_flight,
                    matrix_flight_one, matrix_flight_two, matrix_flight_zero, matrix_length, matrix_luggage_nb, matrix_outbound_date, matrix_return_date,
                    option_loaded, outright_mode, paybox_provider, pledg_provider, payment_remarks, pnr_number, presence_provider, reservation_mode, selected_flight, selected_price,
                    selected_insurances, selected_seats, stations, trace_id, with_applicant } = action.payload
            return {
                ...state,
                accept_cancellation: accept_cancellation,
                accept_confirmation: accept_confirmation,
                alliances: alliances,
                airlines: airlines,
                airports: airports,
                billing_info_mode: billing_info_mode,
                buyer: buyer,
                currency: currency,
                currency_symbol: currency_symbol,
                fees_remark: fees_remark,
                flight_groups: flight_groups,
                flight_list: flight_list,
                flight_list_render: flight_list,
                index_group: index_group,
                initial_deposit: initial_deposit,
                lowest_price: lowest_price,
                lowest_price_one: lowest_price_one,
                lowest_price_two: lowest_price_two,
                lowest_price_zero: lowest_price_zero,
                matrix_data: matrix_data,
                matrix_flight: matrix_flight,
                matrix_flight_one: matrix_flight_one,
                matrix_flight_two: matrix_flight_two,
                matrix_flight_zero: matrix_flight_zero,
                matrix_length: matrix_length,
                matrix_luggage_nb: matrix_luggage_nb,
                matrix_outbound_date: matrix_outbound_date,
                matrix_return_date: matrix_return_date,
                option_loaded: option_loaded,
                outright_mode: outright_mode,
                paybox_provider: paybox_provider,
                pledg_provider: pledg_provider,
                payment_remarks: payment_remarks,
                pnr_number: pnr_number,
                presence_provider: presence_provider,
                reservation_mode: reservation_mode,
                selected_flight: selected_flight,
                selected_insurances: selected_insurances,
                selected_price: selected_price,
                selected_seats: selected_seats,
                stations: stations,
                trace_id: trace_id,
                with_applicant: with_applicant
            }
        }
        case OPEN_PRICE_VARIATION: {
            const open_price_variation = action.payload;
            return {
                ...state,
                open_price_variation: open_price_variation
            }
        }
        case REMOVE_BAGGAGE_OPTION: {
            if (state.selected_flight !== null){
                const { baggage_index, outbound_index, segment_key, traveler_id } = action.payload;
                let copy_flight_list = state.flight_list_render.slice();
                let copy_flight = Object.assign({}, copy_flight_list[state.selected_flight]);
                let copy_outbound = Object.assign({}, copy_flight.outbounds[outbound_index]);
                let copy_leg = Object.assign({}, copy_outbound.legs.find(item => (item.segment_key === segment_key|| item.key === segment_key)));
                let leg_index = copy_outbound.legs.findIndex(item => (item.segment_key === segment_key || item.key === segment_key));
                if (copy_leg !== undefined && leg_index !== -1){
                    let paid_options = Object.assign({}, copy_leg.paid_options);
                    let new_options_travelers: Array<LuggageOption> = [];
                    paid_options[traveler_id].map((option, option_index) => {
                        if (option_index !== baggage_index){
                            new_options_travelers.push(option);
                        }
                    })
                    if (new_options_travelers.length > 0){
                        paid_options[traveler_id] = new_options_travelers;
                    }else{
                        delete paid_options[traveler_id];
                    }
                    copy_leg.paid_options = paid_options;
                    copy_outbound.legs[leg_index] = copy_leg;
                    copy_flight.outbounds[action.payload.outbound_index] = copy_outbound;
                    copy_flight_list[state.selected_flight] = copy_flight;
                }
                return {
                    ...state,
                    flight_list_render: copy_flight_list
                }
            }else{
                return {
                    ...state
                }
            }
        }
        case REMOVE_ERROR: {
            const { group_index, error } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.error = copy_group.error.filter((elem) => {
                return elem !== error
            });
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case REMOVE_PNR_REMARK: {
            const remark_index = action.payload;
            let copy_payment_remarks = state.payment_remarks.slice();
            copy_payment_remarks.splice(remark_index, 1);
            return {
                ...state,
                payment_remarks: copy_payment_remarks
            }
        }
        case REMOVE_PASSENGER_BAGGAGE: {
            const { selected_flight, outbound_index, segment_key, traveler_id } = action.payload;
            let flight_list = state.flight_list_render.slice();
            let copy_flights: Array<Flight> = [];
            flight_list.map((flight, flight_index) => {
                if (flight_index === selected_flight){
                    let copy_flight = Object.assign({}, flight);
                    let copy_outbound = Object.assign({}, copy_flight.outbounds[outbound_index]);
                    let copy_leg = Object.assign({}, copy_outbound.legs.find(item => (item.segment_key === segment_key || item.key === segment_key)));
                    let leg_index = copy_outbound.legs.findIndex(item => (item.segment_key === segment_key || item.key === segment_key));
                    if (copy_leg !== undefined && leg_index !== -1){
                        let paid_options = Object.assign({}, copy_leg.paid_options);
                        if (paid_options[traveler_id] !== undefined){
                            paid_options[traveler_id].map((option) => {
                                if (option.option_on_group) {
                                    copy_outbound.legs.map((parsed_leg, parsed_leg_index) => {
                                        if (parsed_leg_index !== leg_index && parsed_leg.paid_options[traveler_id] !== undefined){
                                            let copy_parsed_leg = Object.assign({}, parsed_leg);
                                            copy_parsed_leg.paid_options[traveler_id].map((paid_option, paid_option_index) => {
                                                if (paid_option.key_optional_service === option.key_optional_service){
                                                    copy_parsed_leg.paid_options[traveler_id].splice(paid_option_index, 1);
                                                }
                                            })
                                            if (Object.keys(copy_parsed_leg.paid_options[traveler_id])){
                                                delete copy_parsed_leg.paid_options[traveler_id];
                                            }
                                            copy_outbound.legs[parsed_leg_index] = copy_parsed_leg;
                                        }
                                    })
                                }

                                if (option.option_on_flight) {
                                    copy_flight.outbounds.map((parsed_outbound, parsed_outbound_index) => {
                                        let copy_parsed_outbound = Object.assign({}, parsed_outbound);
                                        copy_parsed_outbound.legs.map((parsed_leg, parsed_leg_index) => {
                                            if (parsed_leg.segment_key !== segment_key && parsed_leg.key !==segment_key && parsed_leg.paid_options[traveler_id] !== undefined){
                                                let copy_parsed_leg = Object.assign({}, parsed_leg);
                                                copy_parsed_leg.paid_options[traveler_id].map((paid_option, paid_option_index) => {
                                                    if (paid_option.key_optional_service === option.key_optional_service){
                                                        copy_parsed_leg.paid_options[traveler_id].splice(paid_option_index, 1);
                                                    }
                                                })
                                                if (Object.keys(copy_parsed_leg.paid_options[traveler_id])){
                                                    delete copy_parsed_leg.paid_options[traveler_id];
                                                }
                                                copy_parsed_outbound.legs[parsed_leg_index] = copy_parsed_leg;
                                            }
                                        })
                                        copy_flight.outbounds[parsed_outbound_index] = copy_parsed_outbound;
                                    })
                                }
                            })
                            delete paid_options[traveler_id];
                            copy_leg.paid_options = paid_options;
                            copy_outbound.legs[leg_index] = copy_leg;
                            copy_flight.outbounds[outbound_index] = copy_outbound;
                            flight = copy_flight;
                        }
                    }
                }
                copy_flights.push(flight);
            });
            return {
                ...state,
                flight_list_render: copy_flights
            }
        }
        case REMOVE_SELECTED_INSURANCE: {
            const { traveler_id } = action.payload;
            let copy_selected_insurances = Object.assign({}, state.selected_insurances);
            delete copy_selected_insurances[traveler_id]
            return {
                ...state,
                selected_insurances: copy_selected_insurances
            }
        }
        case REMOVE_SELECTED_SEAT: {
            const { segment_index, traveler_id } = action.payload;
            let copy_selected_seats = state.selected_seats.slice();
            let copy_seats_by_segments = state.seats_by_segment.slice();
            let seat_to_del: number | null = null;
            let seat_nb_to_del: string | null = null

            // remove seat from selected seats
            if (segment_index < copy_selected_seats.length){
                copy_selected_seats[segment_index].map((segment_seats, segment_seats_index) => {
                    if (segment_seats.traveler === traveler_id){
                        seat_to_del = segment_seats_index;
                        seat_nb_to_del = segment_seats.seat_number;
                    }
                })
            }

            // change chosen parameters of seat_rows
            if(seat_nb_to_del !== null && segment_index < copy_seats_by_segments.length){
                copy_seats_by_segments[segment_index].seat_rows.map((seat_row) => {
                    seat_row.seats.map((seat) => {
                        if (seat.code === seat_nb_to_del){
                            seat.chosen = false;
                            seat.chosen_traveler = undefined;
                        }
                    })
                })
            }

            if (seat_to_del !== null){
                copy_selected_seats[segment_index].splice(seat_to_del, 1);
            }
            return {
                ...state,
                seats_by_segment: copy_seats_by_segments,
                selected_seats: copy_selected_seats
            }
        }
        case REMOVE_SELECTED_PRICE: {
            const price = action.payload;
            let new_price = state.selected_price - price;
            return {
                ...state,
                selected_price: new_price
            }
        }
        case REMOVE_TRAVELER: {
            const { group_index, pax_type } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let end_date = copy_group.journey.slice(-1)[0].start_date;
            let moment_date = moment.utc();
            if (end_date !== null){
                moment_date = moment.utc(end_date);
            }
            let index_to_remove: number = -1;
            switch(pax_type){
                case "adult":
                    index_to_remove = copy_group.travelers.findIndex(item => moment_date.diff(moment.utc(item.birth_date), "year") >= 12);
                    break
                case "child":
                    index_to_remove = copy_group.travelers.findIndex(item => (12 > moment_date.diff(moment.utc(item.birth_date), "year")) && (moment_date.diff(moment.utc(item.birth_date), "year") >= 2));
                    break
                case "baby":
                    index_to_remove = copy_group.travelers.findIndex(item => 2 > moment_date.diff(moment.utc(item.birth_date), "year"));
                    break
            }
            if (index_to_remove >= 0) {
                copy_group.travelers.splice(index_to_remove, 1);
            }
            copy_flight_groups[group_index] = copy_group;
            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case REPLACE_GROUP: {
            const { group_index, new_group } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            copy_flight_groups[group_index] = new_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }

        }
        case RESET_FETCH_ERROR: {
            return {
                ...state,
                fetch_error: null
            }
        }
        case RESET_STATE: {
            return initialState
        }
        case RESET_ERROR: {
            const group_index = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.error = copy_group.error.filter((elem) => {
                return elem === "";
            });
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case RESET_MATRIX_HOVER: {
            if (state.matrix_flight !== null){
                let matrix_flights = state.matrix_flight.slice();
                let copy_matrix_flight: Array<MatrixFlight> = [];
                matrix_flights.map((matrix_flight, index) => {
                    let copy_flight = Object.assign({}, matrix_flight);
                    copy_flight.hover = false;
                    copy_flight.hover_selected = false;
                    matrix_flight = copy_flight;
                    copy_matrix_flight.push(matrix_flight);
                });
                return {
                    ...state,
                    matrix_flight: copy_matrix_flight
                }
            }else{
                return state
            }
        }
        case RESET_SELECTED_PRICE: {
            return {
                ...state,
                selected_price: 0
            }
        }
        case REVERT_JOURNEYS: {
            const group_index = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);

            let new_journey = <Journey[]>[];
            copy_group.journey.map((journey) => {
                let copy_journey = Object.assign({}, journey);
                copy_journey.origin = journey.destination;
                copy_journey.destination = journey.origin;
                new_journey.push(copy_journey);
            })

            copy_group.journey = new_journey;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SAVE_FLIGHT_GROUPS: {
            let temp_saved_groups = state.previous_flight_groups.slice();
            if (temp_saved_groups.length === 10){
                // remove first elements
                temp_saved_groups.splice(0, state.flight_groups.length);
            }
            temp_saved_groups = temp_saved_groups.concat(state.flight_groups);
            return {
                ...state,
                previous_flight_groups: temp_saved_groups
            }
        }
        case SELECT_AIRLINES: {
            const { group_index, airlines } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.company = airlines;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SELECT_FLIGHT: {
            const flight_index = action.payload;
            if (flight_index !== null){
                let copy_flight_list = state.flight_list_render.slice();
                let copy_flight = Object.assign({}, copy_flight_list[flight_index]);
                copy_flight.outbounds.map((outbound, outbound_index) => {
                    let copy_outbound = Object.assign({}, outbound);
                    copy_outbound.detail = false;
                    copy_flight.outbounds[outbound_index] = copy_outbound;
                });
                copy_flight_list[flight_index] = copy_flight

                return {
                    ...state,
                    flight_list_render: copy_flight_list,
                    selected_flight: flight_index
                }
            }else{
                return {
                    ...state,
                    selected_flight: flight_index
                }
            }
        }
        case SELECT_INSURANCE: {
            let { traveler_id, insurance } = action.payload;
            let copy_selected_insurances = Object.assign({}, state.selected_insurances);
            copy_selected_insurances[traveler_id] = insurance;
            return {
                ...state,
                selected_insurances: copy_selected_insurances
            }
        }
        case SELECT_SEAT: {
            let { segment_index, traveler_id, traveler_name, seat_number, price } = action.payload;
            let copy_selected_seats = state.selected_seats.slice();
            let copy_seats_by_segments = state.seats_by_segment.slice();
            let new_traveler_seat: SelectedSeat = {
                traveler: traveler_id,
                seat_number: seat_number
            }
            let old_seat_number = "";

            if (price !== undefined){
                new_traveler_seat.price = price;
            }

            if (copy_selected_seats[segment_index] === undefined){
                // fill previous segments if empty
                for (let i=0; i < segment_index; i++){
                    if (copy_selected_seats[i] === undefined){
                        copy_selected_seats[i] = [];
                    }
                }
                copy_selected_seats[segment_index] = [new_traveler_seat];
            }else{
                let found = false;
                let old_seat_index: null | number = null;
                copy_selected_seats[segment_index].map((segment_seats, segment_seats_index) => {
                    if (segment_seats.traveler === traveler_id){
                        old_seat_number = segment_seats.seat_number;
                        copy_selected_seats[segment_index][segment_seats_index] = new_traveler_seat;
                        found = true;
                    }else if (segment_seats.seat_number === seat_number){
                        old_seat_index = segment_seats_index;
                    }
                })
                if (old_seat_index !== null){
                    // change seat owner if same seat
                    copy_selected_seats[segment_index].splice(old_seat_index, 1);
                }
                if (!found){
                    copy_selected_seats[segment_index].push(new_traveler_seat);
                }
            }

            if (segment_index < copy_seats_by_segments.length){
                copy_seats_by_segments[segment_index].seat_rows.map((seat_row) => {
                    seat_row.seats.map((seat) => {
                        if (seat.code === seat_number){
                            seat.chosen = true;
                            seat.chosen_traveler = traveler_name;
                        }else if (seat.code === old_seat_number){
                            seat.chosen = false;
                            seat.chosen_traveler = "";
                        }
                    })
                })
            }

            return {
                ...state,
                seats_by_segment: copy_seats_by_segments,
                selected_seats: copy_selected_seats
            }
        }
        case SET_ACCEPT_CANCELLATION: {
            return {
                ...state,
                accept_cancellation: action.payload
            }
        }
        case SET_ACCEPT_CONFIRMATION: {
            return {
                ...state,
                accept_confirmation: action.payload
            }
        }
        case SET_AIRPORT_JOURNEY: {
            const { group_index, journey_index, airport, type } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let copy_journey = Object.assign({}, copy_group.journey[journey_index]);

            if (type === "origin"){
                copy_journey.origin = airport === null ? null : Object.assign({}, airport);
            }else if (type === "destination"){
                copy_journey.destination = airport === null ? null : Object.assign({}, airport);
            }

            copy_group.journey[journey_index] = copy_journey;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_AIRPORT_JOURNEY_MULTI_DEST: {
            const { group_index, journey_index, airport, type } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let copy_journey = Object.assign({}, copy_group.journey[journey_index]);

            if (type === "origin"){
                copy_journey.origin = airport === null ? null : Object.assign({}, airport);
            }else if (type === "destination"){
                copy_journey.destination = airport === null ? null : Object.assign({}, airport);
            }

            copy_group.journey[journey_index] = copy_journey;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_AIRPORT_JOURNEY_ROUNDTRIP: {
            const { group_index, journey_index, airport, type } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);

            let new_journeys = <Journey[]>[];
            copy_group.journey.map((journey, index_journey) => {
                if (index_journey === journey_index) {
                    let copy_journey = Object.assign({}, journey);
                    if (type === "origin")
                        copy_journey.origin = airport === null ? null : Object.assign({}, airport);
                    else if (type === "destination")
                        copy_journey.destination = airport === null ? null : Object.assign({}, airport);
                    journey = copy_journey;
                }
                else if (index_journey !== journey_index) {
                    let copy_journey = Object.assign({}, journey);
                    if (type === "origin")
                        copy_journey.destination = airport === null ? null : Object.assign({}, airport);
                    else if (type === "destination")
                        copy_journey.origin = airport === null ? null : Object.assign({}, airport);
                    journey = copy_journey;
                }
                new_journeys.push(journey);
            });

            copy_group.journey = new_journeys;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_BILLING_INFO_MODE: {
            const billing_info_mode = action.payload;
            return {
                ...state,
                billing_info_mode: billing_info_mode
            }
        }
        case SET_BUYER: {
            const traveler = action.payload;
            return {
                ...state,
                buyer: traveler
            }
        }
        case SET_CHECKED_LUGGAGE: {
            const luggage_key = action.payload;
            let temp_checked_luggage = Object.assign({}, state.checked_luggage);
            console.log("temp_checked_luggage : ", temp_checked_luggage);
            console.log("luggage_key : ", luggage_key);
            let temp_disabled = true;
            Object.keys(temp_checked_luggage).map((key) => {
                if (luggage_key !== "disabled"){
                    if (key === luggage_key){
                        temp_checked_luggage[key] = true;
                    }else if(temp_checked_luggage[key]){
                        temp_checked_luggage[key] = false;
                    }
                    if (temp_checked_luggage[key]){
                        temp_disabled = false;
                    }
                }else{
                    if (key === luggage_key){
                        temp_checked_luggage[key] = true;
                    }else if(temp_checked_luggage[key]){
                        temp_checked_luggage[key] = false;
                    }
                }
            })
            temp_checked_luggage["disabled"] = temp_disabled;
            let copy_flight_list = state.flight_list_render.slice();
            copy_flight_list.map((flight, flight_index) => {
                let copy_flight = Object.assign({}, flight);
                let flight_outbounds = flight.outbounds.slice();
                flight.outbounds.map((outbound_flight, outbound_index) => {
                    let copy_outbound = Object.assign({}, outbound_flight);
                    copy_outbound.detail = false;
                    flight_outbounds[outbound_index] = copy_outbound;
                })
                copy_flight.outbounds = flight_outbounds;
                copy_flight_list[flight_index] = copy_flight;
            })
            return {
                ...state,
                checked_luggage: temp_checked_luggage,
                flight_list_render: copy_flight_list,
                isUpdate: true
            }
        }
        case SET_DEFAULT_AIRPORTS: {
            return {
                ...state,
                default_airports: action.payload
            }
        }
        case SET_DIRECT_FLIGHT: {
            const { direct_flight, one_stop, group_index } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.direct_flight = direct_flight;
            copy_group.one_stop = one_stop;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_DURATION_FILTER: {
            const { min_duration, max_duration } = action.payload;
            return {
                ...state,
                filter_min_duration: min_duration,
                filter_max_duration: max_duration,
                isUpdate: true
            }
        }
        case SET_DURATION_SLIDER: {
            const { min_duration, max_duration } = action.payload;
            return {
                ...state,
                slider_min_duration: min_duration,
                slider_max_duration: max_duration
            }
        }
        case SET_ERROR: {
            const { group_index, error } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.error.push(error);
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_FEES_REMARK: {
            return {
                ...state,
                fees_remark: action.payload
            }
        }
        case SET_FETCH_DATA: {
            const { alliances, airlines, airports, currency, currency_symbol, distance_unit, filter_time, flight_list, max_duration, max_price, max_start_date,
                    min_duration, min_price, min_start_date, stations, stop_over_airport, stop_over_duration, stop_over, trace_id } = action.payload;
            let new_airlines: Array<Airline> | null = state.airlines !== null ? state.airlines.slice() : null;
            let new_airports: AirportsObj | null = state.airports !== null ? Object.assign({}, state.airports) : null;
            if (new_airports !== null){
                if (airports !== null){
                    Object.keys(airports).forEach(key => {
                        if (new_airports !== null) {
                            if(new_airports[key] === undefined)
                                new_airports[key] = airports[key]
                            else if (new_airports[key].price === 0 || airports[key].price < new_airports[key].price){
                                new_airports[key] = airports[key];
                            }
                        }
                    })
                }
            }else
                new_airports = airports;
            if (new_airlines !== null){
                if (airlines !== null){
                    airlines.map((airline) => {
                        if (new_airlines !== null){
                            let found_airline = new_airlines.find(item => item.name === airline.name);
                            if (found_airline === undefined)
                                new_airlines.push(airline)
                            else if (found_airline.price !== undefined && (found_airline.price === 0 || found_airline.price > airline.price)){
                                let new_airline_index = new_airlines.findIndex(item => item.name === airline.name);
                                new_airlines[new_airline_index] = airline;
                            }
                        }
                    })
                }
            }else
                new_airlines = airlines;
            return {
                ...state,
                alliances: alliances,
                airlines: new_airlines,
                airports: new_airports,
                currency: currency,
                currency_symbol: currency_symbol !== undefined ? currency_symbol : "€",
                distance_unit: distance_unit,
                filter_max_duration: max_duration,
                filter_max_price: max_price !== null ? Math.ceil(max_price) : null,
                filter_max_start_date: max_start_date,
                filter_min_duration: min_duration,
                filter_min_price: min_price !== null ? Math.floor(min_price) : null,
                filter_min_start_date: min_start_date,
                filter_time: filter_time,
                flight_list: flight_list,
                isUpdate: true,
                max_start_date: max_start_date,
                max_duration: max_duration,
                max_price: max_price,
                min_duration: min_duration,
                min_price: min_price,
                min_start_date: min_start_date,
                slider_max_duration: max_duration,
                slider_max_price: max_price !== null ? Math.ceil(max_price) : null,
                slider_min_duration: min_duration,
                slider_min_price: min_price !== null ? Math.floor(min_price) : null,
                slider_time: filter_time,
                stations: stations,
                stop_over: stop_over,
                stop_over_airport: stop_over_airport,
                stop_over_duration: stop_over_duration,
                trace_id: trace_id
            }
        }
        case SET_FETCH_DATA_STATUS: {
            return {
                ...state,
                fetched_status: action.payload
            }
        }
        case SET_FETCH_ERROR: {
            return {
                ...state,
                fetch_error: action.payload
            }
        }
        case SET_FETCH_MATRIX_STATUS: {
            return {
                ...state,
                matrix_status: action.payload
            }
        }
        case SET_FLEXIBLE_DATE: {
            const { group_index, flexible_date } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.flexible_date = flexible_date;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_FLIGHT_TO_SHOW_DETAIL: {
            return {...state, flight_to_show_detail: action.payload }
        }
        case SET_FLIGHT_HOURS: {
            if (state.filter_time !== null){
                const { outbound_index, departure_or_arrival, start_hour, end_hour } = action.payload;
                let copy_filter_time = state.filter_time.slice();
                let copy_outbound = Object.assign({}, copy_filter_time[outbound_index]);
                let copy_departure_or_arrival = copy_outbound[departure_or_arrival].slice();
                copy_departure_or_arrival[0] = start_hour;
                copy_departure_or_arrival[1] = end_hour;
                copy_outbound[departure_or_arrival] = copy_departure_or_arrival;
                copy_filter_time[outbound_index] = copy_outbound;

                return {
                    ...state,
                    filter_time: copy_filter_time,
                    isUpdate: true
                }
            }else{
                return state
            }
        }
        case SET_FLIGHT_HOURS_SLIDE: {
            if (state.slider_time !== null){
                const { outbound_index, departure_or_arrival, start_hour, end_hour } = action.payload;
                let copy_slider_time = state.slider_time.slice();
                let copy_outbound = Object.assign({}, copy_slider_time[outbound_index]);
                let copy_departure_or_arrival = copy_outbound[departure_or_arrival].slice();
                copy_departure_or_arrival[0] = start_hour;
                copy_departure_or_arrival[1] = end_hour;
                copy_outbound[departure_or_arrival] = copy_departure_or_arrival;
                copy_slider_time[outbound_index] = copy_outbound;

                return {
                    ...state,
                    slider_time: copy_slider_time,
                }
            }else{
                return state
            }
        }
        case SET_FLIGHT_LIST: {
            return {
                ...state,
                flight_list: action.payload
            }
        }
        case SET_GET_OPTIONS: {
            const { flight_index, options } = action.payload;
            let copy_flight_list = state.flight_list_render.slice();
            copy_flight_list.map((flight, flight_parse_index) => {
                if (flight_parse_index === flight_index){
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.options = options;
                    copy_flight_list[flight_index] = copy_flight
                }
            })
            return {
                ...state,
                trace_id: options.trace_id,
                flight_list_render: copy_flight_list
            }
        }
        case SET_GROUP_INDEX: {
            const group_index = action.payload;

            return {
                ...state,
                index_group: group_index
            }
        }
        case SET_INSURANCE_CHOICES: {
            const choices = action.payload;
            return {
                ...state,
                insurances_choices: choices
            }
        }
        case SET_INCLUDED_LUGGAGE: {
            const { group_index, included_luggage } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.included_luggage = included_luggage;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_INITIAL_DEPOSIT: {
            return {
                ...state,
                initial_deposit: action.payload
            }
        }
        case SET_INITIAL_SEARCH: {
            return {
                ...state,
                initial_search: action.payload
            }
        }
        case SET_JOURNEY: {
            const { group_index, journey } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.journey = journey;
            copy_flight_groups[group_index] = copy_group;

            return{
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_JOURNEY_TYPE: {
            const { group_index, journey_type } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.journey_type = journey_type;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_LOWEST_PRICE_MOBILE: {
            const price = action.payload;
            return {
                ...state,
                lowest_price_mobile: price
            }
        }
        case SET_MANUAL_INFO: {
            const { airlines, airports, flight_list, trace_id, last_ticketing } = action.payload;
            return {
                ...state,
                airlines: airlines,
                airports: airports,
                flight_list: flight_list,
                flight_list_render: flight_list,
                last_ticketing: last_ticketing,
                selected_flight: 0,
                option_loaded: true,
                trace_id: trace_id
            }
        }
        case SET_MATRIX_DATA: {
            const { lowest_price, lowest_price_one, lowest_price_two, lowest_price_zero, matrix_data, matrix_flight, matrix_flight_one,
                    matrix_flight_two, matrix_flight_zero, matrix_length, matrix_luggage_nb, matrix_outbound_date, matrix_return_date } = action.payload;
            console.log("matrix_luggage_nb : ", matrix_luggage_nb);
            return {
                ...state,
                lowest_price: lowest_price,
                lowest_price_one: lowest_price_one,
                lowest_price_two: lowest_price_two,
                lowest_price_zero: lowest_price_zero,
                matrix_data: matrix_data,
                matrix_flight: matrix_flight,
                matrix_flight_one: matrix_flight_one,
                matrix_flight_two: matrix_flight_two,
                matrix_flight_zero: matrix_flight_zero,
                matrix_length: matrix_length,
                matrix_luggage_nb: matrix_luggage_nb,
                matrix_outbound_date: matrix_outbound_date,
                matrix_return_date: matrix_return_date
            }
        }
        case SET_MATRIX_SELECTED_DAY: {
            if (state.matrix_flight !== null && state.matrix_flight_one !== null && state.matrix_flight_two !== null && state.matrix_flight_zero !== null){
                let matrix_flight = state.matrix_flight.slice();
                let matrix_flight_one = state.matrix_flight_one.slice();
                let matrix_flight_two = state.matrix_flight_two.slice();
                let matrix_flight_zero = state.matrix_flight_zero.slice();
                console.log("action.payload.index_start : ", action.payload.index_start);
                console.log("action.payload.index_return : ", action.payload.index_return);
                console.log("matrix_flight : ", matrix_flight);
                matrix_flight.map((flight, index_flight) => {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.selected = false;
                    copy_flight.highlight = false;
                    if (copy_flight.index_start_date === action.payload.index_start && copy_flight.index_return_date === action.payload.index_return) {
                        copy_flight.selected = true;
                    }
                    matrix_flight[index_flight] = copy_flight;
                });

                matrix_flight_one.map((flight, index_flight) => {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.selected = false;
                    copy_flight.highlight = false;
                    if (copy_flight.index_start_date === action.payload.index_start && copy_flight.index_return_date === action.payload.index_return) {
                        copy_flight.selected = true;
                    }
                    matrix_flight_one[index_flight] = copy_flight;
                });

                matrix_flight_two.map((flight, index_flight) => {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.selected = false;
                    copy_flight.highlight = false;
                    if (copy_flight.index_start_date === action.payload.index_start && copy_flight.index_return_date === action.payload.index_return) {
                        copy_flight.selected = true;
                    }
                    matrix_flight_two[index_flight] = copy_flight;
                });

                matrix_flight_zero.map((flight, index_flight) => {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.selected = false;
                    copy_flight.highlight = false;
                    if (copy_flight.index_start_date === action.payload.index_start && copy_flight.index_return_date === action.payload.index_return) {
                        copy_flight.selected = true;
                    }
                    matrix_flight_zero[index_flight] = copy_flight;
                });
                return {
                    ...state,
                    matrix_flight: matrix_flight,
                    matrix_flight_one: matrix_flight_one,
                    matrix_flight_two: matrix_flight_two,
                    matrix_flight_zero: matrix_flight_zero,
                    // matrix_index_start: action.payload.index_start,
                    // matrix_index_return: action.payload.index_return,
                }
            }else{
                return state;
            }
        }
        case SET_MIN_DEPOSIT: {
            return {
                ...state,
                min_deposit: action.payload
            }
        }
        case SET_MULTIDEST_CLASS: {
            const { group_index, cabin_class, journey_index } = action.payload;

            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);

            let copy_journey = Object.assign({}, copy_group.journey[journey_index]);
            copy_journey.class_type = cabin_class;

            copy_group.journey[journey_index] = copy_journey;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_MULTIDEST_START_DATE: {
            const { group_index, date, journey_index } = action.payload;

            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let copy_journeys = copy_group.journey.slice();
            let copy_journey = Object.assign({}, copy_group.journey[journey_index]);
            copy_journey.start_date = date;
            copy_journeys[journey_index] = copy_journey;
            copy_group.journey = copy_journeys;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_NEW_ENCRYPT_DATA: {
            return {
                ...state,
                new_encrypt_data: action.payload
            }
        }
        case SET_ONEWAY_JOURNEY: {
            const group_index = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.journey = copy_group.journey.slice(0, 1);
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_ONEWAY_CLASS: {
            const { group_index, cabin_class } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let copy_journey = Object.assign({}, copy_group.journey[0]);
            copy_journey.class_type = cabin_class;
            copy_group.journey[0] = copy_journey;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_ONEWAY_ORIGIN_DATE: {
            const { group_index, date } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let copy_journeys = copy_group.journey.slice()
            let copy_journey = Object.assign({}, copy_group.journey[0]);
            copy_journey.start_date = date;
            copy_journeys[0] = copy_journey;

            // change travelers birth date to put exact start date day (so that it does not break min-max)
            let copy_travelers = copy_group.travelers;
            copy_travelers.map((traveler) => {
                if (traveler.birth_date !== null){
                    let copy_birth_date = moment(traveler.birth_date);
                    copy_birth_date.date(moment(date).date());
                    traveler.birth_date = copy_birth_date.format("YYYY-MM-DD");
                }
            })

            copy_group.travelers = copy_travelers;
            copy_group.journey = copy_journeys;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_OPEN_ADD_LUGGAGE: {
            return {
                ...state,
                open_add_luggage: action.payload
            }
        }
        case SET_OPEN_CHANGE_MODE: {
            return {
                ...state,
                open_change_mode: action.payload
            }
        }
        case SET_OPEN_CONFIRM_FLIGHT: {
            return {
                ...state,
                open_confirm_flight: action.payload
            }
        }
        case SET_OPEN_GIR_REMINDER: {
            return {
                ...state,
                open_gir_reminder: action.payload
            }
        }
        case SET_OPTION_LOADED: {
            const option_loaded = action.payload;
            return {
                ...state,
                option_loaded: option_loaded
            }
        }
        case SET_OPTION_MODE_ONLY: {
            return {
                ...state,
                option_mode_only: action.payload
            }
        }
        case SET_OPTIONS_ERROR: {
            const { flight_index, error } = action.payload;
            let copy_flight_list = state.flight_list_render.slice();
            copy_flight_list.map((flight, flight_parse_index) => {
                if (flight_parse_index === flight_index){
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.error_options = error;
                    copy_flight_list[flight_index] = copy_flight
                }
            })
            return {
                ...state,
                flight_list_render: copy_flight_list
            }
        }
        case SET_PARAMS_SEARCH: {
            const params_search = action.payload;
            return {
                ...state,
                params_search: params_search
            }
        }
        case SET_PAYBOX_PROVIDER: {
            const paybox_provider = action.payload;
            return {
                ...state,
                paybox_provider: paybox_provider
            }
        }
        case SET_PAYMENT_SCHEDULES: {
            const schedules = action.payload;
            if (schedules !== null){
                return{
                    ...state,
                    payment_four_times: schedules["4x"] !== undefined ? schedules["4x"] : null,
                    payment_three_times: schedules["3x"] !== undefined ? schedules["3x"] : null,
                }
            }else{
                return {
                    ...state,
                    payment_four_times: null,
                    payment_three_times: null
                }
            }
        }
        case SET_PLEDG_PROVIDER: {
            const pledg_provider = action.payload;
            return {
                ...state,
                pledg_provider: pledg_provider
            }
        }
        case SET_PNR_NUMBER: {
            const pnr_number = action.payload;
            return {
                ...state,
                pnr_number: pnr_number
            }
        }
        case SET_PRESENCE_PROVIDER: {
            const presence_provider = action.payload;
            return {
                ...state,
                presence_provider: presence_provider
            }
        }
        case SET_PRICE_FILTER: {
            const { max_price, min_price } = action.payload;
            return {
                ...state,
                filter_min_price: min_price,
                filter_max_price: max_price,
                isUpdate: true
            }
        }
        case SET_PRICE_SLIDER: {
            const { max_price, min_price } = action.payload;
            return {
                ...state,
                slider_min_price: min_price,
                slider_max_price: max_price,
                isUpdate: true
            }
        }
        case SET_PRICE_VARIATION: {
            const price_variation = action.payload;
            return {
                ...state,
                price_variation: price_variation
            }
        }
        case SET_PRIVATE_PRICE: {
            const { group_index, private_price } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.private_price = private_price;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_PUBLIC_PRICE: {
            const { group_index, public_price } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.public_price = public_price;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_PVT_REMARK: {
            return {
                ...state,
                pvt_remark: action.payload
            }
        }
        case SET_ROUNDTRIP_CLASS: {
            const { group_index, cabin_class } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let copy_journey = Object.assign({}, copy_group.journey[1]);
            copy_journey.class_type = cabin_class;
            copy_group.journey[1] = copy_journey;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_ROUNDTRIP_JOURNEY: {
            const group_index = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            copy_group.journey = copy_group.journey.slice(0, 2);
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_ROUNDTRIP_ORIGIN_DATE: {
            const { group_index, date } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let copy_journeys = copy_group.journey.slice();
            let copy_journey = Object.assign({}, copy_group.journey[0]);
            copy_journey.start_date = date;
            copy_journeys[0] = copy_journey;

            let return_journey = Object.assign({}, copy_group.journey[1]);
            return_journey.start_date = moment(date).utc().add(7, "days").utc().format("YYYY-MM-DD[T]HH:mm:ss[Z]");
            copy_journeys[1] = return_journey;

            // change travelers birth date to put exact start date day (so that it does not break min-max)
            let copy_travelers = copy_group.travelers;
            copy_travelers.map((traveler) => {
                if (traveler.birth_date !== null){
                    let copy_birth_date = moment(traveler.birth_date);
                    copy_birth_date.date(moment(date).date());
                    traveler.birth_date = copy_birth_date.format("YYYY-MM-DD");
                }
            })
            copy_group.travelers = copy_travelers;
            copy_group.journey = copy_journeys;
            copy_flight_groups[group_index] = copy_group;
            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_ROUNDTRIP_RETURN_DATE: {
            const { group_index, date } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let copy_journeys = copy_group.journey.slice();
            let copy_journey = Object.assign({}, copy_group.journey[1]);

            copy_journey.start_date = date;
            copy_journeys[1] = copy_journey;
            copy_group.journey = copy_journeys;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SET_SEAT_CURRENT_SEGMENT: {
            return {
                ...state,
                seat_current_segment: action.payload
            }
        }
        case SET_SEATS_BY_SEGMENT: {
            let temp_seat_by_segment = action.payload.slice();
            temp_seat_by_segment.map((seat_by_segment) => {
                seat_by_segment.seat_rows.map((seat_row) => {
                    seat_row.seats.map((seat) => {
                        seat.chosen = false;
                    })
                })
            })
            return {
                ...state,
                seats_by_segment: action.payload
            }
        }
        case SET_START_DATE_FILTER: {
            const { min_date, max_date } = action.payload;
            return {
                ...state,
                filter_max_start_date: max_date,
                filter_min_start_date: min_date,
                isUpdate: true
            }
        }
        case SHOW_ALL_AIRLINES: {
            let new_airlines: Array<Airline> = [];
            if(state.airlines !== null){
                state.airlines.map((airline) => {
                    let copy_airline = Object.assign({}, airline);
                    copy_airline.displayed = false;
                    new_airlines.push(copy_airline);
                })
            }
            return {
                ...state,
                airlines: new_airlines.length !== 0 ? new_airlines : null,
                show_all_airlines: true,
                isUpdate: true
            }
        }
        case SHOW_ALL_AIRPORTS: {
            if (state.airports !== null){
                let copy_airports = Object.assign({}, state.airports)
                Object.keys(copy_airports).forEach(key => {
                    let copy_airport = Object.assign({}, copy_airports[key]);
                    copy_airport.displayed = false;
                    copy_airports[key] = copy_airport;
                })
                return {
                    ...state,
                    airports: copy_airports,
                    show_all_airports: true,
                    isUpdate: true
                }
            }else{
                return state
            }
        }
        case SHOW_ALL_STATIONS: {
            if (state.stations !== null){
                let copy_stations = Object.assign({}, state.stations)
                Object.keys(copy_stations).forEach(key => {
                    let copy_station = Object.assign({}, copy_stations[key]);
                    copy_station.displayed = false;
                    copy_stations[key] = copy_station;
                })
                return {
                    ...state,
                    stations: copy_stations,
                    show_all_stations: true,
                    isUpdate: true
                }
            }else{
                return state
            }
        }
        case SHOW_ALL_STOP_OVER_AIRPORTS: {
            if (state.stop_over_airport !== null){
                let new_airports: Array<Record<string | number, Airport>> = [];
                state.stop_over_airport.map((journey_airports) => {
                    Object.keys(journey_airports).forEach(key => {
                        let copy_airport = Object.assign({}, journey_airports[key]);
                        copy_airport.displayed = false;
                        journey_airports[key] = copy_airport
                    })
                    new_airports.push(journey_airports);
                })
                return {
                    ...state,
                    stop_over_airport: new_airports,
                    show_all_stop_over_airport: true,
                    isUpdate: true
                }

            }else{
                return state
            }
        }
        case SUB_JOURNEY_TO_FLIGHT: {
            const { group_index, journey_index } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);

            copy_group.journey = copy_group.journey.filter((elem, index_elem) => {
                return index_elem !== journey_index;
            });
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case SWAP_MULTIDEST_JOURNEY: {
            const { group_index, old_index, new_index } = action.payload;
            let copy_flight_groups = state.flight_groups.slice();
            let copy_group = Object.assign({}, copy_flight_groups[group_index]);
            let copy_journeys = copy_group.journey.slice();
            let temp_journey = copy_journeys[new_index];

            // keep dates
            let old_date = copy_journeys[old_index].start_date;
            let new_date = copy_journeys[new_index].start_date;

            copy_journeys[new_index] = copy_journeys[old_index];
            copy_journeys[old_index] = temp_journey;

            copy_journeys[old_index].start_date = old_date;
            copy_journeys[new_index].start_date = new_date;

            copy_group.journey = copy_journeys;
            copy_flight_groups[group_index] = copy_group;

            return {
                ...state,
                flight_groups: copy_flight_groups
            }
        }
        case TOGGLE_ACTIVE_STOPOVER_FILTER: {
            const stop_over_key  = action.payload;
            let stop_over_active = Object.assign({}, state.stop_over_active);
            stop_over_active[stop_over_key] = !stop_over_active[stop_over_key];

            let new_show_all_stop_over = ((stop_over_active.none && stop_over_active.one && stop_over_active.two && stop_over_active.more) || (!stop_over_active.none && !stop_over_active.one && !stop_over_active.two && !stop_over_active.more));
            return {
                ...state,
                stop_over_active: stop_over_active,
                show_all_stop_over: new_show_all_stop_over,
                isUpdate: true
            }
        }
        case TOGGLE_ADD_LOYALTY_CARD: {
            return {
                ...state,
                add_loyalty_card: !state.add_loyalty_card
            }
        }
        case TOGGLE_AIRLINE: {
            if (state.airlines !== null){
                const airline_id = action.payload;
                let copy_airlines = state.airlines.slice();
                let copy_airline = Object.assign({}, copy_airlines.find(item => item.id === airline_id))
                let copy_airline_index = copy_airlines.findIndex(item => item.id === airline_id);
                copy_airline.displayed = !copy_airline.displayed;
                copy_airlines[copy_airline_index] = copy_airline;

                let all_true = true;
                let all_false = true;
                copy_airlines.map((airline) => {
                    if (airline.displayed) {
                        all_false = false;
                    } else {
                        all_true = false;
                    }
                });

                return {
                    ...state,
                    airlines: copy_airlines,
                    show_all_airlines: (all_false || all_true),
                    isUpdate: true
                }
            }else{
                return state
            }
        }
        case TOGGLE_AIRPORT: {
            const airport_id = action.payload
            let copy_airports = Object.assign({}, state.airports);
            let copy_airport = Object.assign({}, copy_airports[airport_id]);
            copy_airport.displayed = !copy_airport.displayed;
            copy_airports[airport_id] = copy_airport;
            let all_true = true;
            let all_false = true;
            Object.keys(copy_airports).forEach(key => {
                if (copy_airports[key].displayed) {
                    all_false = false;
                } else {
                    all_true = false;
                }
            });
            return {
                ...state,
                airports: copy_airports,
                show_all_airports: (all_false || all_true),
                isUpdate: true
            }
        }
        case TOGGLE_ALLIANCE: {
            if(state.alliances !== null){
                const alliance_index = action.payload;
                let copy_alliances = state.alliances.slice();
                let copy_alliance = Object.assign({}, copy_alliances[alliance_index]);
                copy_alliance.displayed = !copy_alliance.displayed;
                copy_alliances[alliance_index] = copy_alliance;

                let all_true = true;
                let all_false = true;
                copy_alliances.map((alliance) => {
                    if (alliance.displayed) {
                        all_false = false;
                    } else {
                        all_true = false;
                    }
                });

                return {
                    ...state,
                    alliances: copy_alliances,
                    show_all_alliances: (all_true || all_false),
                    isUpdate: true
                }
            }else{
                return state
            }
        }
        case TOGGLE_ALLOTMENT_PRICE: {
            return {
                ...state,
                allotment_price: !state.allotment_price,
                isUpdate: true
            }
        }
        case TOGGLE_ALL_STOP_OVER_DURATION: {
            let new_stop_over_duration: Array<StopoverDuration> = [];
            if (state.stop_over_duration !== null){
                state.stop_over_duration.map((outbound) => {
                    let copy_outbound = Object.assign({}, outbound);
                    Object.keys(outbound).map((key) => {
                        copy_outbound[key] = false;
                    })
                    new_stop_over_duration.push(copy_outbound);
                })
            }

            return {
                ...state,
                stop_over_duration: new_stop_over_duration,
                show_all_stop_over_duration: true,
                isUpdate: true
            }
        }
        case TOGGLE_BACK_TO_LIST: {
            return {
                ...state,
                back_to_list: !state.back_to_list
            }
        }
        case TOGGLE_DAY_FLIGHT: {
            return {
                ...state,
                is_day_flight: !state.is_day_flight,
                isUpdate: true
            }
        }
        case TOGGLE_DETAIL: {
            const { flight_index, outbound_index, detail } = action.payload;
            let copy_flight_list = state.flight_list_render.slice();
            let copy_flight = Object.assign({}, copy_flight_list[flight_index]);
            let copy_outbound = Object.assign({}, copy_flight.outbounds[outbound_index]);
            copy_outbound.detail = detail;
            copy_flight.outbounds[outbound_index] = copy_outbound;
            copy_flight_list[flight_index] = copy_flight;
            return {
                ...state,
                flight_list_render: copy_flight_list
            }
        }
        case TOGGLE_NIGHT_FLIGHT: {
            return {
                ...state,
                is_night_flight: !state.is_night_flight,
                isUpdate: true
            }
        }
        case SET_REMOVE_BILLING_ID: {
            return {
                ...state,
                remove_billing_id: action.payload
            }
        }
        case SET_REMOVE_INSURANCE: {
            const { open_remove_insurance, traveler_id } = action.payload;
            return {
                ...state,
                open_remove_insurance: open_remove_insurance,
                remove_option_traveler_id: traveler_id
            }
        }
        case SET_REMOVE_LUGGAGE: {
            const { open_remove_luggage, baggage_index, outbound_index, segment_key, traveler_id } = action.payload;
            return {
                ...state,
                open_remove_luggage: open_remove_luggage,
                remove_option_baggage_index: baggage_index,
                remove_option_outbound_index: outbound_index,
                remove_option_segment_key: segment_key,
                remove_option_traveler_id: traveler_id
            }
        }
        case SET_REMOVE_SEAT: {
            const { open_remove_seat, outbound_index, traveler_id } = action.payload;
            return {
                ...state,
                open_remove_seat: open_remove_seat,
                remove_option_outbound_index: outbound_index,
                remove_option_traveler_id: traveler_id
            }
        }
        case TOGGLE_OPEN_SKIP_OPTION: {
            return {
                ...state,
                open_skip_option: !state.open_skip_option
            }
        }
        case TOGGLE_PRIVATE_PRICE: {
            return {
                ...state,
                private_price: !state.private_price,
                isUpdate: true
            }
        }
        case TOGGLE_PROVIDER: {
            const provider_id = action.payload;
            let copy_providers = state.providers.slice();
            let copy_provider = copy_providers.find(item => item.id === provider_id);
            let copy_provider_index = copy_providers.findIndex(item => item.id === provider_id);
            if (copy_provider !== undefined){
                copy_provider.displayed = !copy_provider.displayed;
                copy_providers[copy_provider_index] = copy_provider;
                let all_true = true;
                let all_false = true;
                copy_providers.map((provider) => {
                    if (provider.displayed) {
                        all_false = false;
                    } else {
                        all_true = false;
                    }
                });
                return {
                    ...state,
                    providers: copy_providers,
                    show_all_providers: (all_false || all_true),
                    isUpdate: true
                }
            }
        }
        case TOGGLE_PUBLIC_PRICE: {
            return {
                ...state,
                public_price: !state.public_price,
                isUpdate: true
            }
        }
        case TOGGLE_SHOW_ALL_STOP_OVER: {
            return {
                ...state,
                show_all_stop_over: true,
                isUpdate: true,
                stop_over_active: {
                    "none": false,
                    "one": false,
                    "two": false,
                    "more": false
                }
            }
        }
        case TOGGLE_STATION: {
            const station_id = action.payload
            let copy_stations = Object.assign({}, state.stations);
            let copy_station = Object.assign({}, copy_stations[station_id]);
            copy_station.displayed = !copy_station.displayed;
            copy_stations[station_id] = copy_station;
            let all_true = true;
            let all_false = true;
            Object.keys(copy_stations).forEach(key => {
                if (copy_stations[key].displayed) {
                    all_false = false;
                } else {
                    all_true = false;
                }
            });
            return {
                ...state,
                stations: copy_stations,
                show_all_stations: (all_false || all_true),
                isUpdate: true
            }
        }
        case TOGGLE_STOP_OVER_AIRPORT: {
            if (state.stop_over_airport){
                const { journey_index, airport_id } = action.payload;
                let copy_stop_over_airport = state.stop_over_airport.slice();
                let copy_outbound_stop_over = Object.assign({}, copy_stop_over_airport[journey_index]);
                Object.keys(copy_outbound_stop_over).map((key) => {
                    if (key === airport_id.toString()) {
                        let stop = Object.assign({}, copy_outbound_stop_over[key]);
                        stop.displayed = !stop.displayed;
                        copy_outbound_stop_over[key] = stop;
                    }
                    else {
                        let stop = Object.assign({}, copy_outbound_stop_over[key]);
                        stop.displayed = false;
                        copy_outbound_stop_over[key] = stop;
                    }
                })
                copy_stop_over_airport[journey_index] = copy_outbound_stop_over;

                let all_true = true;
                let all_false = true;
                copy_stop_over_airport.map((outbound) => {
                    Object.keys(outbound).map((airport) => {
                        if (outbound[airport].displayed) {
                            all_false = false;
                        }
                        else {
                            all_true = false;
                        }
                    });
                });
                return {
                    ...state,
                    stop_over_airport: copy_stop_over_airport,
                    show_all_stop_over_airport: (all_false || all_true),
                    isUpdate: true
                }
            }else {
                return state
            }
        }
        case TOGGLE_STOP_OVER_DURATION: {
            const { outbound_index, stop_over_key } = action.payload;
            if (state.stop_over_duration !== null){
                let stop_over_duration = state.stop_over_duration.slice();
                let synchronized_stop_over = state.synchronized_stop_over;
                let new_stop_over_duration: Array<StopoverDuration> = [];
                let temp_show_all_stop_over_duration = true;

                stop_over_duration.map((outbound, stop_over_index) => {
                    if (synchronized_stop_over) {
                        let copy_outbound = Object.assign({}, outbound);
                        Object.keys(copy_outbound).map((key) => {
                            if (key === stop_over_key) {
                                copy_outbound[key] = !stop_over_duration[outbound_index][key];
                            }
                            if (copy_outbound[key]){
                                temp_show_all_stop_over_duration = false;
                            }
                        });
                        outbound = copy_outbound;
                    }
                    else {
                        if (stop_over_index === outbound_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            Object.keys(copy_outbound).map((key) => {
                                if (key === stop_over_key) {
                                    copy_outbound[key] = !copy_outbound[key];
                                }
                                if (copy_outbound[key]){
                                    temp_show_all_stop_over_duration = false;
                                }
                            });
                            outbound = copy_outbound;
                        }
                    }
                    new_stop_over_duration.push(outbound);
                });
                return {
                    ...state,
                    stop_over_duration: new_stop_over_duration,
                    show_all_stop_over_duration: temp_show_all_stop_over_duration,
                    isUpdate: true
                }
            }else{
                return state
            }
        }
        case TOGGLE_SYNCHRONIZED_STOP_OVER: {
            return {
                ...state,
                synchronized_stop_over: !state.synchronized_stop_over
            }
        }
        case TOGGLE_WITH_APPLICANT: {
            return {
                ...state,
                with_applicant: !state.with_applicant
            }
        }
        case UPDATE_FILTERS: {
            const {
                airlines,
                airports,
                alliances,
                checked_luggage,
                is_day_flight,
                is_night_flight,
                private_price,
                providers,
                public_price,
                show_all_stop_over_duration,
                stations,
                stop_over_active,
                stop_over_airport
            } = action.payload;

            return {
                ...state,
                airlines: airlines !== null ?  airlines.sort((a, b) => {return (a.price - b.price)}) : null,
                airports: airports,
                alliances: alliances,
                checked_luggage: checked_luggage,
                is_day_flight: is_day_flight,
                is_night_flight: is_night_flight,
                private_price: private_price,
                providers: providers,
                public_price: public_price,
                show_all_stop_over_duration: show_all_stop_over_duration,
                stations: stations,
                stop_over_active: stop_over_active,
                stop_over_airport: stop_over_airport
            }
        }
        case UPDATE_FLIGHT_LIST: {
            const { flight_list_render, sort_value } = action.payload;
            return {
                ...state,
                flight_list_render: flight_list_render,
                sort_value: sort_value,
                isUpdate: false
            }
        }
        case UPDATE_RETRIEVE_PNR: {
            return {...state, retrieve_pnr: action.payload};
        }
        default:
            return state;

    }
}